import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  completedSkinQuizSelector,
  completedEyeQuizSelector,
  activeSubscriptionsSelect,
  inactiveSubscriptionsSelect,
  lastActiveSkinSubscriptionSelect,
  lastActiveEyeSubscriptionSelect,
  subscriptionsSelect,
  appEditionSelector,
  completedSerumQuizSelector,
  lastActiveSerumSubscriptionSelect
} from '../utils/selectors'
import {
  FUNNEL_NOT_STARTED,
  FUNNEL_QUIZ_COMPLETED,
  FUNNEL_SUBSCRIPTION_INACTIVE,
  FUNNEL_SUBSCRIPTION_ACTIVE,
  KEYWORD_EYE,
  KEYWORD_SKIN,
  LANDING_PAGES_NEUTRAL,
  KEYWORD_SERUM
} from '../constants/funnels'
import { determineFunnelValue, isASkinKeywordProductPage } from '../utils/helpers/funnels'

import { isEyeCreamSubscription, isSerumSubscription, isSystemSubscription } from '../utils/helpers'

const IntentAndFunnelsContext = React.createContext()
const STORAGE_KEY_INTENT = 'proven-user-intent'

function IntentAndFunnelsProvider({ children }) {
  const location = useLocation()
  const completedSkinQuiz = useSelector(state => completedSkinQuizSelector(state))
  const completedEyeQuiz = useSelector(state => completedEyeQuizSelector(state))
  const completedSerumQuiz = useSelector(state => completedSerumQuizSelector(state))
  const activeSubscriptions = useSelector(state => activeSubscriptionsSelect(state))
  const inactiveSubscriptions = useSelector(state => inactiveSubscriptionsSelect(state))
  const allSubscriptions = useSelector(state => subscriptionsSelect(state))
  const appEdition = useSelector(appEditionSelector)
  const lastActiveSkinSubscription = useSelector(state => lastActiveSkinSubscriptionSelect(state))
  const lastActiveEyeSubscription = useSelector(state => lastActiveEyeSubscriptionSelect(state))
  const lastActiveSerumSubscription = useSelector(state => lastActiveSerumSubscriptionSelect(state))

  const [intent, setIntent] = useState('')
  const [skinFunnel, setSkinFunnel] = useState(FUNNEL_NOT_STARTED)
  const [eyeFunnel, setEyeFunnel] = useState(FUNNEL_NOT_STARTED)
  const [serumFunnel, setSerumFunnel] = useState(FUNNEL_NOT_STARTED)

  const updateFunnelIfCorresponds = ({
    completedProductQuiz,
    funnelValue,
    setFunnel,
    productSubscriptionFilter
  }) => {
    const determinedFunnelValue = determineFunnelValue({
      completedProductQuiz,
      activeSubscriptions,
      inactiveSubscriptions,
      productSubscriptionFilter
    })
    if (funnelValue !== determinedFunnelValue) setFunnel(determinedFunnelValue)
  }

  const funnelByProductKeyWord = {
    [KEYWORD_EYE]: {
      funnelValue: eyeFunnel,
      completedProductQuiz: completedEyeQuiz,
      setFunnel: setEyeFunnel,
      productSubscriptionFilter: isEyeCreamSubscription,
      lastActiveSubscription: lastActiveEyeSubscription
    },
    [KEYWORD_SKIN]: {
      funnelValue: skinFunnel,
      completedProductQuiz: completedSkinQuiz,
      setFunnel: setSkinFunnel,
      productSubscriptionFilter: isSystemSubscription,
      lastActiveSubscription: lastActiveSkinSubscription
    },
    [KEYWORD_SERUM]: {
      funnelValue: serumFunnel,
      completedProductQuiz: completedSerumQuiz,
      setFunnel: setSerumFunnel,
      productSubscriptionFilter: isSerumSubscription,
      lastActiveSubscription: lastActiveSerumSubscription
    }
  }

  useEffect(() => {
    // save intent in browser local storage
    const storedIntent = getCachedIntent()
    if (storedIntent) {
      setIntent(storedIntent)
    } else {
      setIntent(KEYWORD_SKIN)
    }
  }, [])

  useEffect(() => {
    // save intent in browser local storage
    if (intent) {
      localStorage.setItem(STORAGE_KEY_INTENT, intent)
    }
  }, [intent])

  // Track user intent by following his navigation
  useEffect(() => {
    if (location) {
      const isOnACongratsPage = location?.pathname?.startsWith('/account/congratulations')
      // track PDPs
      const onNeutralPage = LANDING_PAGES_NEUTRAL.some(url => location.pathname.indexOf(url) > -1)
      if ((!location.pathname.includes('account') && !onNeutralPage) || isOnACongratsPage) {
        const onSkinQuiz =
          location.pathname.indexOf('quiz') > -1 &&
          !Object.keys(funnelByProductKeyWord).find(
            keyWord => keyWord !== KEYWORD_SKIN && location.pathname.indexOf(keyWord) > 0
          )
        const onPageForSkin = isASkinKeywordProductPage(location?.pathname, appEdition)

        // if PATH contains 'eye'
        if (location.pathname.includes(KEYWORD_EYE)) {
          if (intent !== KEYWORD_EYE) setIntent(KEYWORD_EYE)
        } else if (location.pathname.includes(KEYWORD_SERUM)) {
          // if PATH contains 'serum'
          if (intent !== KEYWORD_SERUM) setIntent(KEYWORD_SERUM)
        } else if (
          location.pathname.includes(KEYWORD_SKIN) ||
          onPageForSkin ||
          onSkinQuiz ||
          isOnACongratsPage
        ) {
          // if PATH contains 'skin' OR user is on LPs for skin
          if (intent !== KEYWORD_SKIN) setIntent(KEYWORD_SKIN)
        }
      }

      // track UTMs
      if (location.search) {
        // if UTM contains 'eye'
        if (location.search.includes(KEYWORD_EYE)) {
          if (intent !== KEYWORD_EYE) setIntent(KEYWORD_EYE)
        } else if (location.search.includes(KEYWORD_SERUM)) {
          // if UTM contains 'serum'
          if (intent !== KEYWORD_SERUM) setIntent(KEYWORD_SERUM)
        } else {
          // if UTM exists, default to SKIN intent
          if (intent !== KEYWORD_SKIN) setIntent(KEYWORD_SKIN)
        }
      }
    }
  }, [location])

  // Track users funnels by checking their quiz and subscriptions states
  useEffect(() => {
    //update funnel if corresponds for every product
    Object.keys(funnelByProductKeyWord).forEach(productKeyWord =>
      updateFunnelIfCorresponds(funnelByProductKeyWord[productKeyWord])
    )
  }, [
    completedSkinQuiz,
    completedEyeQuiz,
    completedSerumQuiz,
    activeSubscriptions,
    inactiveSubscriptions
  ])

  const getTargetRoute = funnel => {
    // 'funnel' must be either 'eye', 'skin' or 'serum'
    // if 'funnel' is provided and legit, get route for this funnel
    if (funnel && Object.keys(funnelByProductKeyWord).indexOf(funnel) >= 0) {
      return getRouteInFunnel(funnel)
    }
    // if 'funnel' is not provided, get route based on user's intent
    return getRouteInFunnel(intent)
  }
  const getFunnelByProductKeywordObject = () => {
    return Object.keys(funnelByProductKeyWord).reduce((ob, currentKeyword) => {
      ob[currentKeyword] = funnelByProductKeyWord[currentKeyword].funnelValue
      return ob
    }, {})
  }

  const getRouteInFunnel = funnel => {
    const funnelValue = funnelByProductKeyWord[funnel].funnelValue
    const intentSlug = funnel === KEYWORD_SKIN ? '' : funnel + '/'
    let route = `/quiz/${intentSlug}start`

    if (funnelValue === FUNNEL_QUIZ_COMPLETED) {
      const hasAnySubscription = allSubscriptions && allSubscriptions.length
      // if user has any subscription (past or present), we redirect her to RUD based on the selected funnel
      if (hasAnySubscription) {
        route = funnel === KEYWORD_SKIN ? '/account/congratulations/' : `/account/shop/${funnel}`
      } else {
        route = `/account/congratulations/${intentSlug}`
      }
    } else if (funnelValue === FUNNEL_SUBSCRIPTION_INACTIVE) {
      route = '/account/subscriptions/'
    } else if (funnelValue === FUNNEL_SUBSCRIPTION_ACTIVE) {
      route = `/account/subscriptions/${
        funnelByProductKeyWord[funnel]?.lastActiveSubscription?.id
          ? funnelByProductKeyWord[funnel]?.lastActiveSubscription?.id
          : ''
      }`
    }
    return route
  }

  return (
    <IntentAndFunnelsContext.Provider
      value={{
        intent,
        skinFunnel,
        eyeFunnel,
        serumFunnel,
        getFunnelByProductKeywordObject,
        getTargetRoute
      }}
    >
      {children}
    </IntentAndFunnelsContext.Provider>
  )
}

// Hook to get Intent context
const useIntentContext = () => {
  // get the context
  const context = useContext(IntentAndFunnelsContext)
  if (context === undefined) {
    throw new Error('useIntentContext was used outside of its Provider')
  }
  return context
}

const getCachedIntent = () => {
  return localStorage && localStorage.getItem(STORAGE_KEY_INTENT)
}

export { IntentAndFunnelsProvider, IntentAndFunnelsContext, useIntentContext, getCachedIntent }
