import {
  AUTH_USER,
  AUTH_ERROR,
  SET_AUTH_ERROR,
  CLEAR_AUTH,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_IN_PROGRESS,
  SIGNIN_PASSWORD_SUCCESS,
  SIGNIN_PASSWORD_FAIL,
  SIGNIN_USER_NOT_FOUND,
  SIGNIN_PASSWORD_AUTH_VERFICIATION,
  CLEAR_APP_STATE,
  SUCCESS_START_PASSWORDLESS_AUTH,
  SUCCESS_PASSWORDLESS_AUTH,
  FAIL_PASSWORDLESS_AUTH,
  CLEAR_PASSWORDLESS_AUTH,
  OPEN_LOGIN_SLIDE,
  START_PASSWORDLESS_AUTH_VERFICIATION,
  SET_PATH_BEFORE_LOGIN,
  FIRST_LOGIN_AFTER_COMPLETE_QUIZ,
  SET_LOGOUT_USER_ATTEMPT,
  CLEAR_LOGOUT_USER_ATTEMPT,
  SIGNUP_PASSWORD_AUTH_VERFICIATION
} from '../constants/actionTypes'
import initialState from './initialState'

const INIT = {
  error: '',
  message: '',
  timestamp: '',
  loading: false,
  authenticated: false,
  prepopulatedEmail: null
}

const reducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case OPEN_LOGIN_SLIDE: {
      const { prepopulatedEmail } = action
      return { ...state, prepopulatedEmail }
    }
    case CLEAR_AUTH:
      return {
        ...state,
        ...INIT,
        authenticated: state.authenticated,
        passwordless: state.passwordless,
        prepopulatedEmail: state.prepopulatedEmail
      }
    case AUTH_USER:
      return { ...state, ...INIT, authenticated: true, loading: false }
    case START_PASSWORDLESS_AUTH_VERFICIATION:
    case SIGNIN_PASSWORD_AUTH_VERFICIATION:
      return { ...state, ...INIT, loading: true }
    case SIGNUP_PASSWORD_AUTH_VERFICIATION:
      return { ...state, signupTimestamp: Date.now() }
    case AUTH_ERROR:
      return { ...state, ...INIT, error: action.error, timestamp: action.timestamp, loading: false }
    case SET_AUTH_ERROR:
      return { ...state, error: action.error, timestamp: action.timestamp, loading: false }
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, ...INIT }
    case FORGOT_PASSWORD_IN_PROGRESS:
      return { ...state, ...INIT, loading: true }
    case FORGOT_PASSWORD_ERROR:
      return { ...state, ...INIT, error: action.error, timestamp: action.timestamp }
    case SIGNIN_PASSWORD_SUCCESS: {
      return {
        ...state,
        ...INIT,
        loading: true,
        authenticated: true
      }
    }
    case SIGNIN_PASSWORD_FAIL: {
      return {
        ...state,
        error: action.error,
        errorUsername: action.username,
        message: action.message ?? '',
        loading: false
      }
    }
    case SIGNIN_USER_NOT_FOUND: {
      return {
        ...state,
        error: SIGNIN_USER_NOT_FOUND,
        errorUsername: action.username,
        message: action.message ?? '',
        loading: false
      }
    }
    case CLEAR_APP_STATE: {
      const { pathBeforeLogin } = state
      return {
        ...initialState.auth,
        ...INIT,
        pathBeforeLogin
      }
    }
    case SUCCESS_START_PASSWORDLESS_AUTH:
      return {
        ...state,
        ...INIT,
        passwordless: {
          user_id: action.data.id,
          email: action.data.email
        }
      }
    case SUCCESS_PASSWORDLESS_AUTH:
      return { ...state, ...INIT, loading: false, authenticated: true }
    case FAIL_PASSWORDLESS_AUTH:
      return {
        ...state,
        loading: false,
        passwordless: {
          error: action.data.error,
          errorUsername: action.data.username,
          message: action.data.message
        }
      }
    case CLEAR_PASSWORDLESS_AUTH:
      return {
        ...state,
        passwordless: undefined,
        prepopulatedEmail: state.prepopulatedEmail
      }
    case FIRST_LOGIN_AFTER_COMPLETE_QUIZ:
      return {
        ...state,
        isFirstLoginAfterCompleteQuiz: action.data?.isFirstLoginAfterCompleteQuiz
      }
    case SET_PATH_BEFORE_LOGIN:
      return {
        ...state,
        pathBeforeLogin: action.path
      }
    case SET_LOGOUT_USER_ATTEMPT:
      return { ...state, isLogoutUserAttempt: true }
    case CLEAR_LOGOUT_USER_ATTEMPT:
      return { ...state, isLogoutUserAttempt: false }
    default:
      return state
  }
}

export default reducer
