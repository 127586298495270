import initState from './initialState'
import { GET_TERMS_CONDITOINS_DATA } from '../constants/actionTypes'

const reducer = (state = initState.cms, action) => {
  switch (action.type) {
    case GET_TERMS_CONDITOINS_DATA: {
      return {
        ...state,
        terms: action.terms
      }
    }
    default:
      return state
  }
}

export default reducer
