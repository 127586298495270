import { builder, Builder } from '@builder.io/react'
import '@builder.io/widgets'
import provenApi from './proven-api'
import { provenMainUrl } from '../constants/seo-constants'
import {
  getPageSlug,
  getBlogpostByForceV2,
  getAllBlogpostByForceV2,
  forceGetContentV2
} from './builder-helper'
import { BUILDER_API_KEY } from '../constants/constants'
import { cmsUrl } from '../constants/endpoints'

// Initialize Builder client
builder.init(BUILDER_API_KEY)
builder.apiVersion = 'v3'
// builder.canTrack = false // Warning! this line will disable A/B testing in builder

// Register Builder custom components
import 'components/builder/blocks/passthrough/BlogPostContainer'
import 'components/builder/blocks/sections/BeforeAfterCarousel'
import 'components/builder/blocks/sections/CallToAction'
import 'components/builder/blocks/sections/ConcernsEnvironmentLifestyle'
import 'components/builder/blocks/sections/EditorsPicks'
import 'components/builder/blocks/sections/Evolution'
import 'components/builder/blocks/sections/ProductDetails'
import 'components/builder/blocks/sections/AccessoriesUpsell'
import 'components/builder/blocks/sections/RelatedPosts'
import 'components/builder/blocks/sections/ScientificCitations'
import 'components/builder/blocks/sections/SubscribeAndThrive'
import 'components/builder/blocks/sections/QuizFirstQuestion'
import 'components/builder/blocks/sections/ReviewsYotpo'
import 'components/builder/blocks/sections/EyeCreamDuoWaitlist'
import 'components/builder/blocks/sections/LPProductHero'
import 'components/builder/blocks/shared/Button'
import 'components/builder/blocks/shared/ButtonAddToCart'
import 'components/builder/blocks/shared/ButtonWithIntent'
import 'components/builder/blocks/shared/CountdownTimer'
import 'components/builder/blocks/shared/Icon'
import 'components/builder/blocks/shared/Image'
import 'components/builder/blocks/shared/InfluencerCard'
import 'components/builder/blocks/shared/ResponsiveContainer'
import 'components/builder/blocks/shared/ResponsiveGrid'
import 'components/builder/blocks/shared/Slider'
import 'components/builder/blocks/shared/SocialShare'
import 'components/builder/blocks/shared/Text'
import 'components/builder/blocks/shared/TextField'
import 'components/builder/blocks/shared/TextRich'
import 'components/builder/blocks/shared/Title'
import 'components/builder/blocks/shared/Tooltip'
import 'components/builder/blocks/shared/Video'
import 'components/builder/blocks/shared/WelcomeBackLoginForm'
import 'components/builder/blocks/shared/ReviewStarsForShop'
import 'components/builder/blocks/shared/GiftCertificate'
import 'components/builder/blocks/sections/StarsYotpo'
import 'components/builder/blocks/shared/BeforeAfterCard'
import 'components/builder/blocks/shared/BeforeAfterSlider'
import 'components/builder/blocks/shared/AreaWithNavigation'
import 'components/builder/blocks/sections/SephoraInStoreCallToAction'
import 'components/builder/blocks/sections/SephoraInStoreCover'

Builder.register('insertMenu', {
  name: 'PROVEN UI components',
  items: [
    { name: 'Text' },
    { name: 'Button' },
    { name: 'Button Add to Cart' },
    { name: 'Button With Intent' },
    { name: 'Countdown Timer' },
    { name: 'Icon' },
    { name: 'Gift Certificate' },
    { name: 'Influencer Card' },
    { name: 'Tooltip' },
    { name: 'Form:Input' },
    { name: 'Responsive Container' },
    { name: 'Responsive Grid' },
    { name: 'Welcome Back Login Form' },
    { name: 'Slider' },
    { name: 'Stars Yotpo' },
    { name: 'Before After Card' },
    { name: 'Before After Slider' },
    { name: 'Review Stars For Shop' },
    { name: 'Area With Navigation' }
  ]
})

Builder.register('insertMenu', {
  name: 'PROVEN sections',
  items: [
    { name: 'Related articles' },
    { name: 'Social share' },
    { name: 'Blog post' },
    { name: 'Scientific Citations' },
    { name: 'Editors Picks' },
    { name: 'Call To Action' },
    { name: 'Product Details' },
    { name: 'Accessories Upsell' },
    { name: 'Before After Carousel' },
    { name: 'Evolution' },
    { name: 'Subscribe And Thrive' },
    { name: 'Quiz First Question' },
    { name: 'Reviews Yotpo' },
    { name: 'Concerns Environment And Lifestyle' },
    { name: 'Eyecream Duo Waitlist' },
    { name: 'LP Product Hero' },
    { name: 'Sephora In-Store Call To Action' },
    { name: 'Sephora In-Store Cover' }
  ]
})

// eslint-disable-next-line no-unused-vars
const TEMPLATE_BLOG_POST_V1_INDEX = 1
const TEMPLATE_BLOG_POST_V2_INDEX = 0
const TEMPLATE_BLOG_POSTS_V1_INDEX = 0
const faqsPageTemplateId = 'e698b1a50f6641e287640589af6d67da'

const getEvery = async (builder, modelName, options) => {
  // Max limit supported by Builder content API is 100.
  let limit = options.limit || 100
  let offset = 0
  let nextItems
  let allItems = []

  do {
    nextItems = await builder.getAll(modelName, { ...options, limit, offset })
    allItems = allItems.concat(nextItems)
    offset += limit

    if (allItems.length > limit) {
      throw new Error('Content API returned more items than the limit.')
    }
  } while (allItems.length === limit)

  return allItems
}

class BuilderService {
  constructor() {
    this.client = builder
  }

  async getAllPostsByForce() {
    return await getAllBlogpostByForceV2()
  }

  async getPostByForce(slug) {
    return await getBlogpostByForceV2(slug)
  }

  getPage(slug) {
    return builder
      .get('page', {
        userAttributes: {
          urlPath: '/' + (slug || '')
        },
        includeRefs: true,
        options: { noTraverse: false }
      })
      .toPromise()
  }

  getNotificationBar(slug) {
    const params = {
      includeRefs: true,
      noTraverse: false
    }
    params['userAttributes.urlPath'] = slug || ''
    return provenApi.get(`/${cmsUrl}/notification-bar`, { params })
  }

  getPages() {
    return getEvery(builder, 'page', { options: { noTargeting: true } })
  }

  // builder.get returns undefined when Builder.isEditing === true.
  // This method uses fetch to return the content no matter what, even while editing.
  async forceGetFAQsPageTemplate() {
    const finalOptions = {
      noTargeting: true,
      includeRefs: true,
      noTraverse: false,
      query: {
        id: faqsPageTemplateId
      }
    }

    // call forceGetContentV2 to use retries
    const results = await forceGetContentV2('symbol', finalOptions)

    return results[0]
  }

  // builder.get returns undefined when Builder.isEditing === true.
  // This method uses fetch to return the content no matter what, even while editing.
  async forceGetBlogHomePage() {
    const finalOptions = {
      noTargeting: true,
      includeRefs: true,
      noTraverse: false,
      ignoreAppEdition: true
    }

    // call forceGetContentV2 to use retries
    const results = await forceGetContentV2('blog-home-page', finalOptions)

    // there is 2 template, taken the first one (witch is the older one, v2)
    return results[0]
  }

  // builder.get returns undefined when Builder.isEditing === true.
  // This method uses fetch to return the content no matter what, even while editing.
  async forceGetPostTemplate() {
    const response = await provenApi.get(`${cmsUrl}/blog-post-template`, {
      noTargeting: true,
      includeRefs: true,
      noTraverse: false
    })

    // there is 2 template, taken the first one (witch is the older one, v2)
    if (response?.data?.results && response.data.results.length > 0)
      return response.data.results[TEMPLATE_BLOG_POST_V2_INDEX]

    return null
  }

  // builder.get returns undefined when Builder.isEditing === true.
  // This method uses fetch to return the content no matter what, even while editing.
  async forceGetBlogHomePageTemplate() {
    const finalOptions = {
      noTargeting: true,
      includeRefs: true,
      noTraverse: false,
      ignoreAppEdition: true
    }
    // call forceGetContentV2 to use retries
    const results = await forceGetContentV2('blog-home-page-template', finalOptions)

    // there is 2 template, taken the first one (witch is the older one, v2)
    return results[TEMPLATE_BLOG_POSTS_V1_INDEX]
  }

  getPostSlug(post) {
    const pathParts = post.data.url.split('/')
    const slug = pathParts[pathParts.length - 1]

    return slug
  }

  getPageSlug(page) {
    return getPageSlug(page)
  }

  getPostFeaturedImage(post) {
    return post.data.featuredImage
  }

  getPostRelativeUrl(post) {
    return post.data.url
  }

  getContentItemRelativeUrl(contentItem) {
    return contentItem.data.url
  }

  getContentItemAbsoluteUrl(contentItem) {
    const url = new URL(this.getContentItemRelativeUrl(contentItem), provenMainUrl)

    return url.href
  }
}

const builderService = new BuilderService()

export default builderService
