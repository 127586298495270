import initState from './initialState'
import { CREATE_GORGIAS_TICKET } from '../constants/actionTypes'

const reducer = (state = initState.support, action) => {
  switch (action.type) {
    case CREATE_GORGIAS_TICKET: {
      return {
        ...state,
        gorgiasFormResults: action
      }
    }
    default:
      return state
  }
}

export default reducer
