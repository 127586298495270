import React, { createContext, useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Auth, Hub } from 'aws-amplify'
import {
  SIGNIN_PASSWORD_FAIL,
  SIGNIN_USER_NOT_FOUND,
  SUCCESS_PASSWORDLESS_AUTH,
  START_PASSWORDLESS_AUTH_VERFICIATION
} from 'constants/actionTypes'
import { ALL_LANDING_PAGES } from 'constants/configs/landing'
import {
  checkUserExists,
  clearAuth,
  failPasswordlessAuth,
  forgotPassword as forgotPasswordAction,
  passwordlessSignIn,
  onAuthSetupAndRedirect,
  clearLogoutUserAttempt
} from 'actions/auth.actions'
import { closeLoginSlide } from 'actions/checkout.actions'
import { clearAppState } from 'actions/app.actions'
import { clearAccountData, updateAccountInfo } from 'actions/account.actions'
import { clearPasswordlessAuth, verifyPasswordAuth, checkValidUser } from 'actions/auth0.actions'
import { trackEvent } from 'utils/analytics'
import { isAuthenticated } from 'utils/auth/auth'
import { answersSelectorGeneric } from 'utils/selectors'
import Loading from 'components/shared/loading'
import Cookies from 'cookies-js'
import { setPathBeforeLogin } from '../actions/auth.actions'

const RESET_REQUIRED_ERROR = 'PASSWORD_RESET_REQUIRED'
const SIGNIN_FAIL_ERROR = 'SIGNIN_PASSWORD_FAIL'
const RESET_REQUIRED_CODE = 'PasswordResetRequiredException'
const ITERABLE_EMAIL_CAMPAIGN_ID = 'iterableEmailCampaignId'

const statuses = {
  check_not_initiated: 1,
  logged_in: 2,
  logged_out: 3
}

function useAuthStatus() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const mostRecentQuizId = useSelector(store => store.account?.mostRecentQuizId)
  const userInfo = useSelector(state => state.account?.info)
  const [authStatus, setAuthStatus] = useState(statuses.check_not_initiated)

  useEffect(() => {
    const listenerToken = Hub.listen('auth', ({ payload }) => {
      const { event } = payload
      switch (event) {
        case 'signIn':
          dispatch(updateAccountInfo({ isMagicLinkSent: false }))
          setAuthStatus(statuses.logged_in)
          dispatch(
            onAuthSetupAndRedirect({
              questionnaireId: mostRecentQuizId,
              identify: false
            })
          )
          break
        case 'oAuthSignOut':
          dispatch(updateAccountInfo({ isMagicLinkSent: false }))
          setAuthStatus(statuses.logged_out)
          dispatch(clearAccountData())
          dispatch(clearLogoutUserAttempt()) //just in case there was an unexpected logging out
          dispatch(setPathBeforeLogin(null))
          break
        case 'signOut':
          dispatch(updateAccountInfo({ isMagicLinkSent: false }))
          setAuthStatus(statuses.logged_out)
          dispatch(clearAccountData())
          dispatch(clearLogoutUserAttempt()) //just in case there was an unexpected logging out
          dispatch(clearAppState())
          dispatch(clearAuth())
          dispatch(setPathBeforeLogin(null))
          history.push('/')
          break
      }
    })
    return () => {
      listenerToken()
    }
  }, [])

  useEffect(() => {
    async function check() {
      const checkAuthenicted = await isAuthenticated()
      if (checkAuthenicted && userInfo?.email) {
        setAuthStatus(statuses.logged_in)
      } else {
        setAuthStatus(statuses.logged_out)
      }
    }
    setTimeout(() => {
      check()
    }, 500)
  }, [location.pathname])

  return authStatus
}

function useSignIn() {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLandingPage = ALL_LANDING_PAGES.includes(history.location.pathname)
  return async function signIn(username, password) {
    dispatch(clearAppState())
    dispatch(clearLogoutUserAttempt()) //just in case there was an unexpected logging out
    dispatch(clearPasswordlessAuth())
    if (isLandingPage) {
      trackEvent('landing_login_cta', {
        CTA: 'Start Checkout',
        landing_version: 'JC_Aug',
        type: 'password'
      })
    } else {
      trackEvent('login_cta', {
        CTA: 'Sign In',
        type: 'password'
      })
    }
    try {
      let userExists = false
      try {
        await checkValidUser(username, true)
        userExists = true
      } catch (_err) {
        userExists = false
      }
      if (!userExists) {
        return void dispatch({
          type: SIGNIN_USER_NOT_FOUND,
          error: SIGNIN_FAIL_ERROR,
          username
        })
      }
      const response = await dispatch(verifyPasswordAuth(username, password))
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(closeLoginSlide())
        return void history.push('/account/change-password', {
          username,
          oldPassword: password
        })
      }
      return response
    } catch (err) {
      if (err.code === RESET_REQUIRED_CODE) {
        return void dispatch({
          type: SIGNIN_PASSWORD_FAIL,
          error: RESET_REQUIRED_ERROR,
          username
        })
      }
      return void dispatch({
        type: SIGNIN_PASSWORD_FAIL,
        error: SIGNIN_FAIL_ERROR,
        username
      })
    }
  }
}

function useForgotPassword() {
  const dispatch = useDispatch()
  return async function forgotPassword(email) {
    try {
      const data = await dispatch(checkUserExists(email))
      const resetRequired = data.status === 'RESET_REQUIRED'
      await dispatch(forgotPasswordAction(email))
      trackEvent('login_cta', {
        CTA: { ctaText: 'Reset Password' },
        type: resetRequired ? 'Cognito Reset Password' : 'Forgot Password',
        email: email
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

function useStartPasswordlessAuth() {
  const dispatch = useDispatch()
  const mostRecentAnswers = useSelector(store => {
    if (!store.account?.mostRecentQuizId) return undefined
    const { mostRecentQuizId } = store.account
    const answersSelector = answersSelectorGeneric(mostRecentQuizId)
    return answersSelector(store)
  })
  const forgotPassword = useForgotPassword()
  return async function startPasswordlessAuth(email, { saveQuizAnswers = false } = {}) {
    const data = await dispatch(checkUserExists(email))
    const iterableEmailCampaignId = Cookies.get(ITERABLE_EMAIL_CAMPAIGN_ID)
    if (data.status === 'RESET_REQUIRED') {
      await forgotPassword(email)
      return false
    }
    if (saveQuizAnswers) {
      await dispatch(passwordlessSignIn({ email, iterableEmailCampaignId, mostRecentAnswers }))
    } else {
      await dispatch(passwordlessSignIn({ email, iterableEmailCampaignId }))
    }
    return true
  }
}

function useVerifyPasswordlessAuth() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async function verifyPasswordlessAuth({ email, token }) {
    const iterableEmailCampaignId = Cookies.get(ITERABLE_EMAIL_CAMPAIGN_ID)
    try {
      dispatch({ type: START_PASSWORDLESS_AUTH_VERFICIATION })
      const cognitoUser = await Auth.signIn(email)
      await Auth.sendCustomChallengeAnswer(cognitoUser, token) // discard the challenge result
      dispatch({ type: SUCCESS_PASSWORDLESS_AUTH })
    } catch (err) {
      dispatch(passwordlessSignIn({ email, iterableEmailCampaignId }))
      history.push({ pathname: '/', state: { openLoginSlide: true } })
      dispatch(failPasswordlessAuth(email, true))
    }
  }
}

const AuthContext = createContext()

function Provider({ children }) {
  const signIn = useSignIn()
  const forgotPassword = useForgotPassword()
  const startPasswordlessAuth = useStartPasswordlessAuth()
  const authStatus = useAuthStatus()
  const verifyPasswordlessAuth = useVerifyPasswordlessAuth()
  if (authStatus === statuses.check_not_initiated) return <Loading />
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authStatus === statuses.logged_in,
        forgotPassword,
        signIn,
        startPasswordlessAuth,
        verifyPasswordlessAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuthContext = () => {
  // get the context
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('AuthContext was used outside of its Provider')
  }
  return context
}

export { Provider as default, AuthContext, useAuthContext }
