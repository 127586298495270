import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
import { createBrowserHistory, createMemoryHistory } from 'history'
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import createRootReducer from '../reducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export const history = isServer
  ? createMemoryHistory({
      initialEntries: ['/']
    })
  : createBrowserHistory()

// export const history = createHistory();

const persistConfig = {
  key: 'root4',
  // storage: new CookieStorage(Cookies, {}),
  storage,
  blacklist: ['router' /*, 'admin'*/] // router will not be persisted
}
const persistedReducer = persistReducer(persistConfig, createRootReducer())

function configureStoreProd(initialState) {
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk
  ]

  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  )

  const persistor = persistStore(store)

  return { store, persistor }
}

function configureStoreDev(initialState) {
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk
  ]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextCreateRootReducer = require('../reducers').default // eslint-disable-line global-require
      store.replaceReducer(persistReducer(persistConfig, nextCreateRootReducer()))
    })
  }

  const persistor = persistStore(store, {})

  return { store, persistor }
}

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export const { store, persistor } = configureStore()
