import { PRODUCT_SYNC } from '../constants/actionTypes'

const init = {
  items: []
}

const reducer = (state = init, action) => {
  switch (action.type) {
    case PRODUCT_SYNC: {
      return {
        ...state,
        items: action.items
      }
    }
    default:
      return state
  }
}

export default reducer
