import initState from './initialState'
import {
  CLEAR_APP_VARIANTS,
  SET_EXPERIMENT_VARIANT,
  SET_APP_MESSAGE,
  CLEAR_APP_STATE,
  SET_APP_ABOVE_HEADER_MESSAGE,
  SET_APP_IS_WELCOME_BACK_PAGE,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_PROVEN_HEADER_MODE,
  SET_APP_EDITION,
  SET_SUBSCRIPTION_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE,
  SET_ONE_TIME_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE
} from '../constants/actionTypes'
import {
  ONE_TIME_OPTION_FOR_NEXT_ACTION,
  SUBSCRIPTION_OPTION_FOR_NEXT_ACTION
} from '../constants/products'

const reducer = (state = initState.app, action) => {
  switch (action.type) {
    case SET_EXPERIMENT_VARIANT:
      return {
        ...state,
        experiments: {
          ...state.experiments,
          [action.payload.experimentName]: action.payload.variant
        }
      }
    case CLEAR_APP_VARIANTS:
      return {
        ...state,
        experiments: {}
      }
    case SET_APP_MESSAGE: {
      return {
        ...state,
        message: action.message,
        touchedFields: action.touchedFields
      }
    }
    case CLEAR_APP_STATE: {
      return {
        ...initState.app,
        experiments: state.experiments,
        appEdition: state.appEdition,
        provenHeaderMode: state.provenHeaderMode
      }
    }
    case SET_APP_ABOVE_HEADER_MESSAGE: {
      return {
        ...state,
        aboveHeaderMsgRenderContent: action.aboveHeaderMsgRenderContent
      }
    }
    case SET_APP_IS_WELCOME_BACK_PAGE: {
      return {
        ...state,
        isWelcomeBackPage: action.isWelcomeBackPage
      }
    }
    case OPEN_MOBILE_MENU: {
      return {
        ...state,
        mobileMenuOpen: true
      }
    }
    case CLOSE_MOBILE_MENU: {
      return {
        ...state,
        mobileMenuOpen: false
      }
    }
    case CREATE_NOTIFICATION: {
      return {
        ...state,
        notifications: [
          ...(state.notifications || []),
          {
            key: action.key,
            htmlContent: action.htmlContent,
            strong: action.strong,
            text: action.text,
            options: action.options
          }
        ]
      }
    }
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      }
    case DELETE_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications.filter(n => n.key !== action.key)]
      }
    }
    case SET_PROVEN_HEADER_MODE: {
      return {
        ...state,
        provenHeaderMode: { isMobileMode: action.payload.isMobileMode, isReady: true }
      }
    }
    case SET_APP_EDITION: {
      return {
        ...state,
        appEdition: action.edition
      }
    }
    case SET_SUBSCRIPTION_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE: {
      return {
        ...state,
        subscriptionOrOneTimeForNextActionOnCurrentProductPage: SUBSCRIPTION_OPTION_FOR_NEXT_ACTION
      }
    }
    case SET_ONE_TIME_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE: {
      return {
        ...state,
        subscriptionOrOneTimeForNextActionOnCurrentProductPage: ONE_TIME_OPTION_FOR_NEXT_ACTION
      }
    }
    default:
      return state
  }
}

export default reducer
