import axios from 'axios'
import { Auth } from 'aws-amplify'
import { provenPayUrl } from '../constants/endpoints'

const provenPay = axios.create({
  baseURL: provenPayUrl
})

provenPay.interceptors.response.use(
  repsponse => repsponse,
  error => {
    if (error.response.status === 401) {
      Auth.signOut()
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

export default provenPay
