import { APP_EDITION_PROVEN } from '../constants/constants'
import { SUBSCRIPTION_OPTION_FOR_NEXT_ACTION } from '../constants/products'
import { questionnairesInitialReduxState } from '../utils/questionnaire-configurator'

export default {
  app: {
    process: false,
    message: '',
    touchedFields: [],
    experiments: {},
    aboveHeaderMsgRenderContent: undefined,
    mobileMenuOpen: false,
    cartOpen: false,
    notifications: [],
    provenHeaderMode: { isMobileMode: undefined, isReady: false },
    appEdition: APP_EDITION_PROVEN,
    subscriptionOrOneTimeForNextActionOnCurrentProductPage: SUBSCRIPTION_OPTION_FOR_NEXT_ACTION,
    isWelcomeBackPage: false
  },
  admin: {
    users: [],
    orders: [],
    skus: {},
    userSearch: '',
    setSku: { isLoading: false, sets: [], error: null }
  },
  quiz: {
    followUpQuestions: null, //we use this??
    followUpAnswers: null, //we use this??

    questionnaires: questionnairesInitialReduxState,
    version_a: {}, // TODO CHECK IF it is need
    version_b: {}, // TODO CHECK IF it is need
    referrer: null,
    transitionQuestion: {}
  },
  account: {
    info: {
      firstName: null,
      lastName: null,
      email: null,
      zipCode: null,
      countryCode: 'US',
      rudPageCallSuccess: false,
      isMagicLinkSent: false
    },
    paymentMethod: {
      name: null,
      last4: null,
      exp_month: null,
      exp_year: null
    },
    shippingAddress: {
      firstName: null,
      lastName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null
    },
    billingAddress: {
      firstName: null,
      lastName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null
    },
    referrals: {
      referrerId: null,
      pendingReferrals: []
    },
    results: {},
    serumResults: {},
    products: {},
    upcomingSets: [],
    defaultCountryCode: 'US',
    mostRecentQuizId: null,
    currency: null,
    completedSkinQuiz: false,
    completedEyeQuiz: false,
    completedSerumQuiz: false,
    subscriptionOrdersInDraft: [],
    subscriptionOrdersInAwaitingPayment: [],
    subscriptionOrdersInPending: [],
    isLoadingSubscriptionOrders: false,
    error: null,
    sephoraStoreId: null,
    sephoraAdvisorName: null,
    leadEmail: null,
    orderDetailsByOrderId: {}
  },
  auth: {
    error: null,
    pathBeforeLogin: null,
    errorUsername: null,
    prepopulatedEmail: null,
    isFirstLoginAfterCompleteQuiz: false,
    isLogoutUserAttempt: false
  },
  weather: {
    airQuality: null,
    uv: null,
    forecast: null,
    water: null
  },
  cms: {
    blogs: [],
    reviews: []
  },
  cart: {
    openCartPopup: false,
    items: {},
    orderedSubscribablePriceIds: [],
    mostRecentOrderItems: {},
    accessories: {},
    isCheckingCoupon: false
  },
  checkout: {
    error: null,
    progress: false,
    status: null,
    summary: null,
    openSlide: false,
    states: [],
    coupon: null,
    giftCard: null,
    openLoginSlide: false,
    openOrderPopup: false,
    userSkippedAccessories: false,
    orderClosing: { isInProgress: false, error: undefined },
    invoiceDetails: {
      isLoading: false,
      error: undefined,
      invoice: {
        lineItems: []
      }
    }
  },
  support: {
    gorgiasFormResults: {}
  },
  shop: {
    shopProducts: [],
    shopCart: {},
    shopSubscription: {},
    accessories: []
  }
}
