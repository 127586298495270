import {
  SET_COUPON,
  GET_ORDER_DETAILS_SUCCESS,
  SET_STATUS,
  SUCCESS_LOAD_STATES,
  SUCCESS_SUBSCRIBE_PRODUCT,
  OPEN_CHECKOUT_SLIDE,
  CLOSE_CHECKOUT_SLIDE,
  OPEN_LOGIN_SLIDE,
  CLOSE_LOGIN_SLIDE,
  CLEAR_APP_STATE,
  SET_CHECKOUT_STEP,
  SET_HAS_SENT_ORDER_COMPLETED,
  SET_CJEVENT,
  SET_MOCK_CART_ID,
  SET_MOCK_ORDER_ID,
  SET_MOCK_CHECKOUT_ID,
  OPEN_CHECKOUT_POPUP,
  CLOSE_CHECKOUT_POPUP,
  APPLY_GIFT_CARD,
  OPEN_ORDER_TOTAL,
  CLOSE_ORDER_TOTAL,
  SET_SKIP_ACCESSORIES,
  SET_ACCESSORIES_PURCHASE_PREVIEW,
  CLOSE_ORDER_REQUEST_IN_PROGRESS,
  CLOSE_ORDER_REQUEST_SUCCESS,
  CLOSE_ORDER_REQUEST_ERROR,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_ERROR,
  GET_INVOICE_DETAILS_IN_PROGRESS,
  REQUEST_FAIL
} from '../constants/actionTypes'
import initialState from './initialState'

const TWO_DAYS = 172800000 // 2 days in miliseconds

const reducer = (state = initialState.checkout, action) => {
  switch (action.type) {
    case APPLY_GIFT_CARD: {
      const { code } = action
      return { ...state, giftCard: code }
    }
    case SET_COUPON: {
      const { coupon } = action
      const now = Date.now()
      const timeElapsed = now - state.couponLastAdd
      // coupon expired or no coupon
      if ((!coupon || state.coupon === coupon) && timeElapsed > TWO_DAYS) {
        return {
          ...state,
          coupon: null,
          couponLastAdd: null
        }
      }
      // new coupon added
      if (!state.coupon || state.coupon !== coupon) {
        return {
          ...state,
          coupon: coupon,
          couponLastAdd: now
        }
      }
      // remove coupon
      if (coupon === null && state.coupon) {
        return {
          ...state,
          coupon: null,
          couponLastAdd: null
        }
      }

      // current coupon is valid
      return state
    }
    case SET_CJEVENT: {
      const { cjevent } = action
      return { ...state, cjevent }
    }
    case SET_MOCK_CART_ID: {
      const { mockCartId } = action
      return { ...state, mockCartId }
    }
    case SET_MOCK_ORDER_ID: {
      const { mockOrderId } = action
      return { ...state, mockOrderId }
    }
    case SET_MOCK_CHECKOUT_ID: {
      const { mockCheckoutId } = action
      return { ...state, mockCheckoutId }
    }
    case GET_ORDER_DETAILS_SUCCESS: {
      const { orderDetails } = action
      return { ...state, orderDetails, error: null }
    }
    case SET_STATUS: {
      const { status } = action
      return { ...state, status }
    }
    case SUCCESS_LOAD_STATES: {
      const { states } = action
      return { ...state, states }
    }
    case SUCCESS_SUBSCRIBE_PRODUCT: {
      return { ...state, coupon: '' }
    }
    case OPEN_CHECKOUT_SLIDE: {
      return {
        ...state,
        openSlide: true
      }
    }
    case CLOSE_CHECKOUT_SLIDE: {
      return {
        ...state,
        openSlide: false
      }
    }
    case SET_CHECKOUT_STEP: {
      const { step } = action
      return {
        ...state,
        checkoutStep: step
      }
    }
    case OPEN_LOGIN_SLIDE: {
      const { openLoginParams } = action
      return {
        ...state,
        openLoginSlide: openLoginParams
      }
    }
    case CLOSE_LOGIN_SLIDE: {
      return {
        ...state,
        openLoginSlide: false
      }
    }
    case CLEAR_APP_STATE: {
      return {
        ...initialState.checkout,
        openLoginSlide: state.openLoginSlide,
        giftCard: state.giftCard,
        coupon: state.coupon,
        couponLastAdd: state.couponLastAdd,
        cjevent: state.cjevent
      }
    }
    case SET_HAS_SENT_ORDER_COMPLETED: {
      const { hasSentOrderCompleted } = action
      return {
        ...state,
        hasSentOrderCompleted: hasSentOrderCompleted
      }
    }
    case OPEN_CHECKOUT_POPUP: {
      return {
        ...state,
        openPopup: true
      }
    }
    case CLOSE_CHECKOUT_POPUP: {
      return {
        ...state,
        openPopup: false
      }
    }
    case OPEN_ORDER_TOTAL: {
      return {
        ...state,
        openOrderPopup: true
      }
    }
    case CLOSE_ORDER_TOTAL: {
      return {
        ...state,
        openOrderPopup: false
      }
    }
    case SET_SKIP_ACCESSORIES: {
      const { value } = action
      return {
        ...state,
        userSkippedAccessories: value
      }
    }
    case SET_ACCESSORIES_PURCHASE_PREVIEW: {
      const { previewDetails } = action
      return {
        ...state,
        accessoriesPurchasePreview: previewDetails
      }
    }
    case CLOSE_ORDER_REQUEST_IN_PROGRESS: {
      return {
        ...state,
        orderClosing: { isInProgress: true }
      }
    }
    case CLOSE_ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        orderClosing: { isInProgress: false }
      }
    }
    case CLOSE_ORDER_REQUEST_ERROR: {
      const { error } = action
      return {
        ...state,
        orderClosing: { isInProgress: false, error }
      }
    }

    case GET_INVOICE_DETAILS_IN_PROGRESS: {
      return {
        ...state,
        invoiceDetails: { ...state.invoiceDetails, isLoading: true }
      }
    }
    case GET_INVOICE_DETAILS_SUCCESS: {
      const { invoiceDetails } = action.payload
      return {
        ...state,
        invoiceDetails: { ...invoiceDetails, isLoading: false },
        error: null
      }
    }
    case GET_INVOICE_DETAILS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        invoiceDetails: { ...state.invoiceDetails, error, isLoading: false }
      }
    }
    case REQUEST_FAIL: {
      const { error } = action.payload
      return {
        ...state,
        error
      }
    }

    default:
      return state
  }
}

export default reducer
