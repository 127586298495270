import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_UPDATE_ITEM,
  CART_INCREASE_QTY,
  CART_DECREASE_QTY,
  CART_CLEAR,
  CART_CHANGE_QTY,
  CART_OPEN,
  CART_CLOSE,
  CART_ADD_ACCESSORY_ITEM,
  CART_REMOVE_ACCESSORY_ITEM,
  START_CHECKING_COUPON_CODE,
  STOP_CHECKING_COUPON_CODE,
  SET_ORDERED_SUBSCRIBABLE_PRICE_IDS
} from '../constants/actionTypes'
import initialState from './initialState'

const reducer = (state = initialState.cart, action) => {
  switch (action.type) {
    case CART_CHANGE_QTY: {
      const itemToUpdate = state.items[action.priceId]
      if (!itemToUpdate) return state
      return {
        ...state,
        items: {
          ...state.items,
          [action.priceId]: {
            ...itemToUpdate,
            qty: itemToUpdate.qty > 0 ? itemToUpdate.qty + 1 : 1
          }
        }
      }
    }
    case CART_INCREASE_QTY: {
      const item = state.items[action.priceId]
      return {
        ...state,
        items: {
          ...state.items,
          [action.priceId]: { ...item, qty: item.qty > 0 ? item.qty + 1 : 1 }
        }
      }
    }
    case CART_DECREASE_QTY: {
      const item = state.items[action.priceId]
      if (item.qty <= 1) return state
      return {
        ...state,
        items: {
          ...state.items,
          [action.priceId]: { ...item, qty: item.qty - 1 }
        }
      }
    }
    case CART_ADD_ITEM: {
      const priceId = action.priceItem.id
      const disableModeSwitch = action.disableModeSwitch
      if (state.items[priceId] && state.items[priceId].qty === action.qty) return state
      return {
        ...state,
        items: {
          ...state.items,
          [priceId]: { qty: action.qty, period: action.priceItem.period, disableModeSwitch }
        }
      }
    }
    case CART_REMOVE_ITEM: {
      const newItems = Object.fromEntries(
        Object.entries(state.items).filter(([priceId]) => priceId !== action.priceId)
      )
      return {
        ...state,
        items: newItems
      }
    }
    case CART_UPDATE_ITEM: {
      const itemToUpdate = state.items[action.priceId]
      const newItem = itemToUpdate?.qty
        ? {
            ...itemToUpdate,
            ...action.cartItem
          }
        : { ...action.cartItem }

      return {
        ...state,
        items: {
          ...state.items,
          [action.priceId]: newItem
        }
      }
    }
    case CART_OPEN: {
      return {
        ...state,
        openCartPopup: true
      }
    }
    case CART_CLOSE: {
      return {
        ...state,
        openCartPopup: false
      }
    }
    case CART_CLEAR: {
      return {
        ...initialState.cart,
        mostRecentOrderItems: state.items
      }
    }
    case CART_ADD_ACCESSORY_ITEM: {
      return {
        ...state,
        accessories: {
          ...state.accessories,
          [action.priceId]: { qty: 1 }
        }
      }
    }
    case CART_REMOVE_ACCESSORY_ITEM: {
      const newAccessories = Object.fromEntries(
        Object.entries(state.accessories).filter(([priceId]) => priceId !== action.priceId)
      )
      return {
        ...state,
        accessories: newAccessories
      }
    }
    case START_CHECKING_COUPON_CODE: {
      return {
        ...state,
        isCheckingCoupon: true
      }
    }
    case STOP_CHECKING_COUPON_CODE: {
      return {
        ...state,
        isCheckingCoupon: false
      }
    }
    case SET_ORDERED_SUBSCRIBABLE_PRICE_IDS: {
      return {
        ...state,
        orderedSubscribablePriceIds: action.orderedSubscribablePriceIds
      }
    }
    default:
      return state
  }
}

export default reducer
