import initState from './initialState'
import {
  SET_ACCOUNT_DATA,
  CLEAR_ACCOUNT_DATA,
  SET_ACCOUNT_SHIPPING_ADDRESS,
  SET_ACCOUNT_BILLING_ADDRESS,
  SET_ACCOUNT_PERSONAL_INFO,
  SET_ACCOUNT_PAYMENT_METHOD,
  UNSUBSCRIBE_START,
  UNSUBSCRIBE_SUCCESS,
  SET_ACCOUNT_ERROR,
  CLEAR_ACCOUNT_ERROR,
  GET_REFERRAL_DASHBOARD,
  GET_ORDER_HISTORY,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_SUCCESS,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_ERROR,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_IN_PROGRESS,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_SUCCESS,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_ERROR,
  GET_RESULTS,
  GET_PRODUCTS,
  CLEAR_APP_STATE,
  SET_USER_BLUEPRINT_CHART,
  SET_LOYALTY_PROGRAM,
  SET_ACCOUNT_INFO,
  SET_ACCOUNT_PREFERENCE,
  SET_ACCOUNT_FEEDBACK,
  GET_EYE_CREAM_QUIZ_RESULTS,
  GET_SERUM_RESULTS,
  PRODUCT_SYNC,
  SET_LATEST_SETS,
  GET_SUBSCRIPTIONS,
  SET_ACCOUNT_COMPLETE_QUIZ,
  SET_MOST_RECENT_QUIZ_ID,
  SET_SEPHORA_STORE_ID,
  SET_SEPHORA_ADVISOR_NAME,
  SET_LEAD_EMAIL,
  GET_SUBSCRIPTION_ORDERS_IN_PENDING_SUCCESS,
  GET_SUBSCRIPTION_ORDERS_IN_PENDING_ERROR,
  SET_ORDER_DETAILS
} from '../constants/actionTypes'
import { AWAITING_PAYMENT, DRAFT } from '../constants/order-status'

const reducer = (state = initState.account, action) => {
  switch (action.type) {
    case SET_ACCOUNT_DATA: {
      return {
        ...state,
        ...action.account
      }
    }
    case CLEAR_APP_STATE:
    case CLEAR_ACCOUNT_DATA: {
      const countryCode = state.info.countryCode
      const { defaultCountryCode, currency, mostRecentQuizId } = state
      return {
        ...initState.account,
        currency,
        defaultCountryCode,
        mostRecentQuizId,
        info: {
          ...initState.account.info,
          countryCode // TODO can be removed after BE returns countryCode for users PD-1088
        }
      }
    }
    case SET_ACCOUNT_SHIPPING_ADDRESS: {
      return {
        ...state,
        shippingAddress: action.shippingAddress
      }
    }
    case SET_ACCOUNT_PERSONAL_INFO: {
      return {
        ...state,
        info: {
          ...state.info,
          ...action.data
        }
      }
    }
    case SET_ACCOUNT_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.data
      }
    }
    case SET_ACCOUNT_BILLING_ADDRESS: {
      return {
        ...state,
        billingAddress: action.billingAddress
      }
    }
    case UNSUBSCRIBE_START: {
      return state
    }
    case UNSUBSCRIBE_SUCCESS: {
      return {
        ...state,
        subscription: false
      }
    }
    case SET_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    case CLEAR_ACCOUNT_ERROR: {
      return {
        ...state,
        error: ''
      }
    }
    case GET_REFERRAL_DASHBOARD: {
      return {
        ...state,
        referrals: {
          referrerId: action.referrals.referrerId,
          pendingReferrals: action.referrals.pendingReferrals
        }
      }
    }
    case GET_ORDER_HISTORY: {
      return {
        ...state,
        orders: action.orders
      }
    }
    case GET_SUBSCRIPTION_ORDERS_IN_DRAFT_SUCCESS: {
      return {
        ...state,
        subscriptionOrdersInDraft: action.payload.orders
      }
    }
    case GET_SUBSCRIPTION_ORDERS_IN_PENDING_SUCCESS: {
      return {
        ...state,
        subscriptionOrdersInPending: action.payload.orders
      }
    }
    case GET_SUBSCRIPTION_ORDERS_IN_DRAFT_ERROR:
    case GET_SUBSCRIPTION_ORDERS_IN_PENDING_ERROR: {
      return {
        ...state,
        error: action.payload.error
      }
    }
    case GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_IN_PROGRESS: {
      return {
        ...state,
        isLoadingSubscriptionOrders: true
      }
    }
    case GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoadingSubscriptionOrders: false,
        subscriptionOrdersInDraft: action.payload.orders.filter(o => o.status === DRAFT),
        subscriptionOrdersInAwaitingPayment: action.payload.orders.filter(
          o => o.status === AWAITING_PAYMENT
        )
      }
    }
    case GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_ERROR: {
      return {
        ...state,
        isLoadingSubscriptionOrders: false,
        error: action.payload.error
      }
    }
    case GET_RESULTS: {
      return {
        ...state,
        results: action.data.result
      }
    }
    case GET_PRODUCTS: {
      return {
        ...state,
        products: action.data
      }
    }
    case SET_USER_BLUEPRINT_CHART: {
      return {
        ...state,
        blueprint: action.data.result
      }
    }
    case SET_LOYALTY_PROGRAM: {
      return {
        ...state,
        loyaltyProgram: action.data
      }
    }
    case SET_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.data
      }
    }
    case SET_ACCOUNT_PREFERENCE: {
      return {
        ...state,
        results: {
          ...state.results,
          preferences: action.data && action.data.result
        }
      }
    }
    case SET_ACCOUNT_FEEDBACK: {
      return {
        ...state,
        results: {
          ...state.results,
          feedback: action.data && action.data.result
        }
      }
    }
    case SET_LATEST_SETS: {
      return {
        ...state,
        latestSetsV2: action.data
      }
    }
    case GET_EYE_CREAM_QUIZ_RESULTS: {
      return {
        ...state,
        eyeCreamQuizResult: action.data
      }
    }
    case GET_SERUM_RESULTS: {
      return {
        ...state,
        serumResults: action.data.result
      }
    }

    case PRODUCT_SYNC: {
      const currency = action.items[0].one_time_price.currency_code
      return {
        ...state,
        currency
      }
    }
    case SET_MOST_RECENT_QUIZ_ID: {
      return {
        ...state,
        mostRecentQuizId: action.id
      }
    }
    case GET_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: action.subscriptions
      }
    }
    case SET_ACCOUNT_COMPLETE_QUIZ: {
      return {
        ...state,
        ...action.data
      }
    }
    case SET_SEPHORA_STORE_ID: {
      return {
        ...state,
        sephoraStoreId: action.sephoraStoreId
      }
    }
    case SET_SEPHORA_ADVISOR_NAME: {
      return {
        ...state,
        sephoraAdvisorName: action.sephoraAdvisorName
      }
    }
    case SET_LEAD_EMAIL: {
      return {
        ...state,
        leadEmail: action.leadEmail
      }
    }
    case SET_ORDER_DETAILS: {
      const lastFetchTimestamp = Date.now()
      return {
        ...state,
        orderDetailsByOrderId: {
          ...state.orderDetailsByOrderId,
          [action.data.order]: { ...action.data, lastFetchTimestamp }
        }
      }
    }
    default:
      return state
  }
}

export default reducer
