'use strict'

// Adapted from Builder React SDK.

const noop = () => null

// Allow us to require things dynamically safe from webpack bundling
export const safeDynamicRequire = module => {
  const getModule = typeof require === 'function' ? eval('require') : noop

  return getModule(module)
}
