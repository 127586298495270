import axios from 'axios'
import { Auth } from 'aws-amplify'
import { apiUrl } from '../constants/endpoints'

const provenApi = axios.create({
  baseURL: apiUrl
})

provenApi.interceptors.response.use(
  repsponse => repsponse,
  error => {
    if (error?.response?.status === 401) {
      Auth.signOut()
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

export default provenApi
