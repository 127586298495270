import React, { useContext, useEffect, useRef, useState } from 'react'
import { refreshYotpoWidgets } from '../utils/yotpo'

const YotpoContext = React.createContext()
const INTERVAL_TIME = 3000 //5 SECONDS

function YotpoProvider({ children }) {
  const [yotpoReady, setYotpoReady] = useState(false)
  const timer = useRef(null)

  useEffect(() => {
    timer.current = setInterval(() => {
      refreshYotpoWidgets()
      if (window.yotpo?.initialized) {
        // stop interval if yotpo is ready
        clearInterval(timer.current)
        // render yotpo widget if yotpo is ready
        setYotpoReady(true)
      }
    }, INTERVAL_TIME)

    return () => clearInterval(timer?.current)
  }, [])
  //
  // const getYotpoInitialized = () => {
  //   return yotpoReady
  // }

  return (
    <YotpoContext.Provider value={{ yotpoReady /*, skinFunnel, eyeFunnel, getYotpoInitialized*/ }}>
      {children}
    </YotpoContext.Provider>
  )
}

// Hook to get Yotpo context
const useYotpoContext = () => {
  // get the context
  const context = useContext(YotpoContext)
  if (context === undefined) {
    throw new Error('useYotpoContext was used outside of its Provider')
  }
  return context
}

export { YotpoProvider, YotpoContext, useYotpoContext }
