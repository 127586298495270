import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import quiz from './quiz.reducer'
import account from './account.reducer'
import auth from './auth.reducer'
import app from './app.reducer'
import checkout from './checkout.reducer'
import cart from './cart.reducer'
import product from './product.reducer'
import admin from './admin.reducer'
import cms from './cms.reducer'
import support from './support.reducer'
import shop from './shop.reducer'

export default () =>
  combineReducers({
    app,
    quiz,
    account,
    form,
    auth,
    checkout,
    cart,
    product,
    admin,
    cms,
    support,
    shop
  })
