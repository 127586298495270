import React from 'react'
import { SnackbarProvider } from 'notistack'
// import makeStyles from '@material-ui/styles/makeStyles'
import useCheckRUDPage from '../hooks/useCheckRUDPage'
// import { ThemeProvider, StyledEngineProvider } from '@mui/material'

// const useStyles = makeStyles(theme => ({
//   success: {
//     backgroundColor: 'rgb(49 49 49)',
//     '& #notistack-snackbar': {
//       alignItems: 'flex-start',
//       '& > svg': {
//         marginTop: '4px'
//       }
//     }
//   },
//   root: {
//     width: '100%'
//   },
//   containerRoot: {
//     width: '100%',
//     maxWidth: '100%',
//     '& > div': {
//       width: '100%'
//     },
//     [theme.breakpoints.down('sm')]: {
//       paddingLeft: '15px',
//       paddingRight: '15px'
//     },
//     [theme.breakpoints.up('md')]: {
//       paddingLeft: '248px', // 200: sidebar, 48: content
//       paddingRight: '48px'
//     },
//     [theme.breakpoints.up('lg')]: {
//       paddingLeft: '319px', // 247: sidebar, 72: content
//       paddingRight: '72px'
//     },
//     [theme.breakpoints.up('xl')]: {
//       paddingLeft: '455px', // 3280: sidebar, 75: content
//       paddingRight: '75px'
//     }
//   }
// }))

function ProvenSnackbarProvider({ children }) {
  // const classes = useStyles()
  const classes = {}
  const isAccountPage = useCheckRUDPage()

  return (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
        root: isAccountPage ? classes.root : '',
        containerRoot: isAccountPage ? classes.containerRoot : ''
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default ProvenSnackbarProvider
