import initState from './initialState'
import {
  SET_USER_SEARCH,
  GET_USER_DATA,
  GET_PROFILE_DATA,
  UPDATE_ORDER_VAS,
  GET_ORDERS,
  UPDATE_SKUS,
  GET_USER_NOTES_DATA,
  GET_ORDER_NOTES_DATA,
  REMOVE_ORDER_NOTES_DATA,
  GET_SKUS_DATA,
  CREATE_ORDER,
  UPDATE_ORDER_SHIPPING_ADDRESS,
  UPDATE_USER_SHIPPING_ADDRESS,
  UPDATE_USER_EMAIL,
  VERIFY_ORDER,
  SET_INVENTORY,
  GET_SET_SKU_IN_PROGRESS,
  GET_SET_SKU_SUCCESS,
  GET_SET_SKU_ERROR,
  DELETE_SET_SKU_IN_PROGRESS,
  DELETE_SET_SKU_SUCCESS,
  DELETE_SET_SKU_ERROR,
  CREATE_SET_SKU_IN_PROGRESS,
  CREATE_SET_SKU_SUCCESS,
  CREATE_SET_SKU_ERROR,
  UPDATE_SET_SKU_IN_PROGRESS,
  UPDATE_SET_SKU_SUCCESS,
  UPDATE_SET_SKU_ERROR
} from '../constants/actionTypes'

const reducer = (state = initState.admin, action) => {
  switch (action.type) {
    case SET_INVENTORY: {
      const { inventory, skuMap } = action.payload
      return { ...state, inventory, skuMap }
    }
    case SET_USER_SEARCH: {
      const { userSearch } = action
      return {
        ...state,
        userSearch
      }
    }
    case GET_USER_DATA: {
      return {
        ...state,
        users: action.data.users,
        count: action.data.count
      }
    }
    case GET_SKUS_DATA: {
      return {
        ...state,
        skus: action.data
      }
    }
    case GET_PROFILE_DATA: {
      const { users } = state
      let updatedUsers = []
      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        if (users[i].email.toLowerCase() === action.email.toLowerCase()) {
          let updatedUser = {
            ...user,
            result_details: user.result_details || {}
          }
          updatedUser.result_details[action.data.id] = action.data
          updatedUsers.push(updatedUser)
        } else {
          updatedUsers.push(user)
        }
      }

      return {
        ...state,
        users: updatedUsers
      }
    }
    case CREATE_ORDER: {
      const { users } = state
      let updatedUsers = []
      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        if (users[i].id === action.userId) {
          let updatedUser = { ...user }
          updatedUser.orders.push(action.order)
          updatedUsers.push(updatedUser)
        } else {
          updatedUsers.push(user)
        }
      }
      return {
        ...state,
        users: updatedUsers
      }
    }
    case GET_USER_NOTES_DATA: {
      const { users } = state
      let updatedUsers = []
      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        if (users[i].id === action.id) {
          let updatedUser = { ...user, notes: action.data || {} }
          updatedUsers.push(updatedUser)
        } else {
          updatedUsers.push(user)
        }
      }
      return {
        ...state,
        users: updatedUsers
      }
    }
    case UPDATE_USER_SHIPPING_ADDRESS: {
      const { users } = state
      let updatedUsers = []
      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        if (users[i].id === action.user.id) {
          const u = {
            ...users[i],
            shippingAddress: action.user.shippingAddress
          }
          updatedUsers.push(u)
        } else {
          updatedUsers.push(user)
        }
      }
      return {
        ...state,
        users: updatedUsers
      }
    }

    case UPDATE_USER_EMAIL: {
      const { users } = state
      let updatedUsers = []
      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        if (users[i].id === action.user.id) {
          const u = { ...users[i], info: action.user.info }
          updatedUsers.push(u)
        } else {
          updatedUsers.push(user)
        }
      }
      return {
        ...state,
        users: updatedUsers
      }
    }
    case UPDATE_ORDER_SHIPPING_ADDRESS: {
      const { orders } = state
      let updatedOrders = []
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i]
        if (orders[i]._id === action.order._id) {
          const o = {
            ...orders[i],
            shippingAddress: action.order.shippingAddress
          }
          updatedOrders.push(o)
        } else {
          updatedOrders.push(order)
        }
      }
      return {
        ...state,
        orders: updatedOrders
      }
    }
    case VERIFY_ORDER: {
      const { orders } = state
      let updatedOrders = []
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i]
        const updatedOrder = action.data.find(o => o._id === order._id)
        if (updatedOrder) {
          updatedOrders.push(updatedOrder)
        } else {
          updatedOrders.push(order)
        }
      }
      return {
        ...state,
        orders: updatedOrders
      }
    }
    case GET_ORDER_NOTES_DATA: {
      const { orders } = state
      let updatedOrders = []
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i]
        if (orders[i]._id === action.id) {
          let updatedOrder = { ...order, notes: action.data || {} }
          updatedOrders.push(updatedOrder)
        } else {
          updatedOrders.push(order)
        }
      }
      return {
        ...state,
        orders: updatedOrders
      }
    }
    case REMOVE_ORDER_NOTES_DATA: {
      const { orders } = state
      let updatedOrders = []
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i]
        if (orders[i]._id === action.id) {
          let updatedOrder = { ...order, notes: [] }
          updatedOrders.push(updatedOrder)
        } else {
          updatedOrders.push(order)
        }
      }
      return {
        ...state,
        orders: updatedOrders
      }
    }
    case GET_ORDERS: {
      return {
        ...state,
        orders: action.data.orders,
        orderCount: action.data.count
      }
    }
    case UPDATE_ORDER_VAS: {
      return {
        ...state,
        orders: state.orders.map(someOrder => {
          if (someOrder._id !== action.orderId) return someOrder
          return { ...someOrder, vas: action.vas }
        })
      }
    }
    case UPDATE_SKUS: {
      const { orders } = state
      let updatedOrders = []

      for (let i = 0; i < orders.length; i++) {
        const order = orders[i]
        if (orders[i]._id === action.data._id) {
          updatedOrders.push({ ...order, ...action.data })
        } else {
          updatedOrders.push(order)
        }
      }

      return {
        ...state,
        orders: updatedOrders
      }
    }
    case GET_SET_SKU_IN_PROGRESS: {
      return { ...state, setSku: { isLoading: true, sets: [] } }
    }
    case GET_SET_SKU_SUCCESS: {
      const { setSkus } = action.payload
      return { ...state, setSku: { isLoading: false, sets: setSkus } }
    }
    case GET_SET_SKU_ERROR: {
      const { error } = action.payload
      return { ...state, setSku: { isLoading: false, error, sets: [] } }
    }
    case DELETE_SET_SKU_IN_PROGRESS: {
      return { ...state, setSku: { ...state.setSku, isLoading: true } }
    }
    case DELETE_SET_SKU_SUCCESS: {
      const { setSku } = action.payload
      const setSkus = state.setSku.sets.filter(s => s.setSku !== setSku)
      return { ...state, setSku: { isLoading: false, sets: setSkus } }
    }
    case DELETE_SET_SKU_ERROR: {
      const { error } = action.payload
      return { ...state, setSku: { ...state.setSku, isLoading: false, error } }
    }
    case CREATE_SET_SKU_IN_PROGRESS: {
      return { ...state, setSku: { ...state.setSku, isLoading: true } }
    }
    case CREATE_SET_SKU_SUCCESS: {
      const { setSku } = action.payload
      const setSkus = [...state.setSku.sets]
      setSkus.push(setSku)
      return { ...state, setSku: { isLoading: false, sets: setSkus } }
    }
    case CREATE_SET_SKU_ERROR: {
      const { error } = action.payload
      return { ...state, setSku: { ...state.setSku, isLoading: false, error } }
    }
    case UPDATE_SET_SKU_IN_PROGRESS: {
      return { ...state, setSku: { ...state.setSku, isLoading: true } }
    }
    case UPDATE_SET_SKU_SUCCESS: {
      const { setSku } = action.payload
      const index = state.setSku.sets.findIndex(s => s.setSku === setSku.setSku)
      const setSkus = [
        ...state.setSku.sets.slice(0, index),
        setSku,
        ...state.setSku.sets.slice(index + 1)
      ]
      return { ...state, setSku: { isLoading: false, sets: setSkus } }
    }
    case UPDATE_SET_SKU_ERROR: {
      const { error } = action.payload
      return { ...state, setSku: { ...state.setSku, isLoading: false, error } }
    }

    default:
      return state
  }
}

export default reducer
