import initState from './initialState'
import {
  GET_SHOP_PRODUCTS,
  UPDATE_SHOP_SUBSCRIPTION,
  CLEAR_APP_STATE,
  SET_ACCESSORIES
} from '../constants/actionTypes'

const reducer = (state = initState.shop, action) => {
  switch (action.type) {
    case GET_SHOP_PRODUCTS: {
      return {
        ...state,
        shopProducts: action.shopProducts
      }
    }
    case UPDATE_SHOP_SUBSCRIPTION: {
      return {
        ...state,
        shopSubscription: action.shopSubscription
      }
    }
    case CLEAR_APP_STATE: {
      return {
        ...initState.shop
      }
    }
    case SET_ACCESSORIES: {
      return {
        ...state,
        accessories: action.accessories
      }
    }
    default:
      return state
  }
}

export default reducer
