import { runWithRetries } from '../utils/misc'
import { APP_EDITION } from '../constants/constants'
import traverse from 'traverse'
import { api, stringToFunction } from './builder/stringToFunction'
import { set } from './builder/set'
import { builder, Builder } from '@builder.io/react'
import { isEqual } from '../utils/helpers'
import provenApi from './proven-api'
import { cmsUrl } from '../constants/endpoints'

/**
 * TIME IN MILLISECONDS TO WAIT UNTIL RETRY
 * @type {number}
 */
const DELAY_RETRIES = 1000
/**
 * QTY OF RETRIES THE SAME REQUEST
 * @type {number}
 */
const MAX_RETRIES = 3
/**
 * MAX QUANTITY OF REQUESTS ALLOW TO FETCH ONE MODEL (ONE COLLECTION) IN BUILDER.IO
 * @type {number}
 */
const MAX_REQUEST = 100

// eslint-disable-next-line no-unused-vars
const MAX_LEVEL_REFERENCES = 3
const Device = { desktop: 0, tablet: 1, mobile: 2 }
/**
 * This are the only field need for SEO from the page, pdp, ingredient-page, skin-concern-page, and skin-type pages
 * @type {string}
 */
const SEO_FIELDS_FROM_BUILDERIO =
  'id,name,query,data.featuredImage,data.url,data.title,data.summary,data.seoImage,data.structuredData'

export const getPageSlug = page => {
  let url = page.data.url
  if (Array.isArray(url)) {
    // get first element
    // TODO CHECK FOR multiple targets
    url = url[0]
  }

  const pathParts = url.split('/')
  const slug = pathParts.splice(1, pathParts.length - 1).join('/')

  return slug
}

/**
 * This method uses fetch to return the content no matter what, even while editing.
 * this method use content api V2
 * this method by default DO NOT revolve refs
 * @returns {Promise<*>}
 */
const forceGetContentV2NoRetries = async (
  model,
  includeRefs = false,
  limitPerRequest = 20,
  offset = 0,
  userAttributes,
  query,
  data,
  fields
) => {
  const params = {
    includeRefs,
    noTraverse: false,
    limit: limitPerRequest,
    offset,
    data
  }

  if (userAttributes?.urlPath) {
    params['userAttributes.urlPath'] = userAttributes?.urlPath
  }

  if (userAttributes?.appEdition) {
    params['userAttributes.appEdition'] = userAttributes?.appEdition
  }

  if (query?.id) {
    params['query.id'] = query?.id
  }

  if (query?.name) {
    params['query.name'] = query?.name
  }
  if (query?.fieldIn) {
    params[`query.data.${query?.fieldIn.fieldName}.$in`] = JSON.stringify(query?.fieldIn.in)
  }

  if (fields) {
    params['fields'] = fields
  }

  if (query?.variant) {
    params['query.variant'] = query?.variant
  }

  if (query?.enrich) {
    params['enrich'] = query.enrich
  }

  if (data) {
    for (const [key, value] of Object.entries(data)) {
      params[`data.${key}`] = value
    }
  }

  const response = await provenApi.get(`${cmsUrl}/${model}`, {
    params
  })

  const resData = response?.data?.results
  return resData
}

const buildCallbackForRunWithRetries = (
  model,
  includeRefs,
  limitPerRequest,
  offset,
  userAttributes,
  query,
  data,
  fields
) => {
  const fn = async () => {
    return await forceGetContentV2NoRetries(
      model,
      includeRefs,
      limitPerRequest,
      offset,
      userAttributes,
      query,
      data,
      fields
    )
  }

  return fn
}

/**
 *
 * @param total: max qty elements to get
 * @param itemsQty: current items qty
 * @param amountDataQty: qty items get last fequest
 * @param limitPerRequest: limit of elements to get by request
 * @param tries: qty of request's tries
 * @returns {boolean}: return TRUE if should keep fetching data
 */
const getConditionToKeepFetchData = (total, itemsQty, amountDataQty, limitPerRequest, tries) => {
  /**
   * this is an extra layer of security so as not to keep searching forever for data in a model in builder.io.
   */
  if (tries >= MAX_REQUEST) {
    throw Error('maximum request quantity reached')
  }

  if (total) {
    /**
     * if has a max total then check if there are more items (amountDataQty > 0)
     * and current itemsQty do not pass total
     */
    return amountDataQty === limitPerRequest && itemsQty < total && amountDataQty > 0
  } else {
    /**
     * check if there are more items:
     *  amountDataQty is 0 => no more items
     *  amountDataQty is less than limitPerRequest => reach the last items
     */
    return amountDataQty > 0 && amountDataQty === limitPerRequest
  }
}

/**
 This method uses fetch to return the content no matter what, even while editing.
 this method use content api V2
 this method receives all the BuilderIO endpoint query options and filters in the builderIOquery param
 by default DO NOT revolve refs
 * @param model
 * @param builderIOquery
 * @param options: {
 *     maxTries: by default are MAX_RETRIES
 *     total: max qty elements to be return
 * }
 * @returns {Promise<*[]>}
 */
export const forceGetContentByQueryFilterV2 = async (model, builderIOquery, options = {}) => {
  const { maxTries = MAX_RETRIES, total } = options

  if (!model) {
    throw Error('forceGetContentByQueryFilterV2 need a model to fetch')
  }

  let itemsQty = 0
  let offset = 0
  let amountDataQty = 0
  let tries = 0
  const response = []

  do {
    const params = { ...builderIOquery, offset }
    const fnCallback = async () => {
      const response = await provenApi.get(`${cmsUrl}/${model}`, {
        params
      })
      return response?.data?.results
    }
    const data = await runWithRetries(fnCallback, { maxTries, delay: DELAY_RETRIES })
    if (!data) {
      throw Error(
        `forceGetContentByQueryFilterV2 have NO DATA model: ${model} limitPerRequest: ${builderIOquery.limit} offset: ${offset} queryFilter: ${builderIOquery}`
      )
    }
    response.push(...data)
    amountDataQty = data.length
    offset += amountDataQty
    itemsQty += amountDataQty
    tries += 1
  } while (getConditionToKeepFetchData(total, itemsQty, amountDataQty, builderIOquery.limit, tries))

  return response
}

/**
 * This method uses fetch to return the content no matter what, even while editing.
 * this method use content api V2
 * this method by default DO NOT revolve refs
 * options: {
 *     includedRefs
 *     maxTries: by default are MAX_RETRIES
 *     limitPerRequest
 *     total: max qty elements to be return
 * }
 * @returns {Promise<*>}
 */
export const forceGetContentV2 = async (model, options = {}) => {
  const {
    includeRefs,
    maxTries = MAX_RETRIES,
    limitPerRequest = 20,
    total,
    userAttributes,
    query,
    data,
    ignoreAppEdition, // if it is true, we ignore the appEdition
    fields
  } = options

  let userAttributesWithAppEdition = userAttributes

  if (!ignoreAppEdition) {
    userAttributesWithAppEdition = {
      appEdition: APP_EDITION,
      ...(userAttributes || {})
    }
  }

  if (!model) {
    throw Error('forceGetContentV2 need a model to fetch')
  }

  let itemsQty = 0
  let offset = 0
  let amountDataQty = 0
  let tries = 0
  const response = []

  do {
    const fnCallback = buildCallbackForRunWithRetries(
      model,
      includeRefs,
      limitPerRequest,
      offset,
      userAttributesWithAppEdition,
      query,
      data,
      fields
    )
    const modelData = await runWithRetries(fnCallback, { maxTries, delay: DELAY_RETRIES })

    if (!modelData) {
      throw Error(
        `forceGetContentV2 have NO DATA model: ${model} limitPerRequest: ${limitPerRequest} offset: ${offset} query: ${query}`
      )
    }

    response.push(...modelData)

    amountDataQty = modelData.length
    offset += amountDataQty
    itemsQty += amountDataQty
    tries += 1
  } while (getConditionToKeepFetchData(total, itemsQty, amountDataQty, limitPerRequest, tries))

  return response
}

/**
 * get all categories
 * @param options
 * @returns {Promise<*>}
 */
export const getAllCategoriesByForceV2 = async (options = {}) => {
  const innerOptions = {
    maxTries: MAX_RETRIES,
    limitPerRequest: 10,
    ...options
  }
  // call forceGetContentV2 to use retries
  const categories = await forceGetContentV2('category', innerOptions)
  return categories
}

const _populateCategoriesInRelatedArticlesInBlogpost = (category, categories) => {
  const fullCategory = categories?.find(c => c?.id === category?.category?.id)

  if (fullCategory) {
    return {
      category: {
        ...category,
        value: fullCategory
      }
    }
  } else {
    return category
  }
}

const _populatedRelatedArticlesInBlogpost = (relatedPost, categories) => {
  const relatedPostPopulated = {
    ...relatedPost,
    post: {
      ...relatedPost.post,
      value: {
        ...relatedPost?.post?.value,
        data: {
          ...relatedPost?.post?.value?.data,
          categories: relatedPost?.post?.value?.data?.categories?.map(c =>
            _populateCategoriesInRelatedArticlesInBlogpost(c, categories)
          )
        }
      }
    }
  }

  return relatedPostPopulated
}

/**
 *
 * @param blogpost: current blogpost
 * @param categories: all categories
 * @returns {*&{data: (*&{relatedPosts: unknown[] | undefined})}}
 */
export const populateRelatedArticles = (blogpost, categories) => {
  const blogpostPopulated = {
    ...blogpost,
    data: {
      ...blogpost.data,
      relatedPosts: blogpost?.data?.relatedPosts?.map(rp =>
        _populatedRelatedArticlesInBlogpost(rp, categories)
      )
    }
  }

  return blogpostPopulated
}

/**
 * get search blogpost by slug and populate "by hand" the categories of the related articles (and any other references)
 * @param slug
 * @returns {Promise<*|null>}
 */
export const getBlogpostByForceV2 = async slug => {
  const options = {
    userAttributes: {
      urlPath: '/blog/' + (slug || '')
    },
    maxTries: MAX_RETRIES,
    limitPerRequest: 10,
    total: 10
  }
  return await getBlogpostByForceV2ByOptions(options)
}

/**
 * get search blogpost by slug and populate "by hand" the categories of the related articles (and any other references)
 * @param slug
 * @returns {Promise<*|null>}
 */
export const getBlogpostByForceV2ById = async id => {
  const options = {
    query: {
      id: id
    },
    maxTries: MAX_RETRIES,
    limitPerRequest: 10,
    total: 10
  }
  return await getBlogpostByForceV2ByOptions(options)
}

/**
 * get search blogpost by slug and populate "by hand" the categories of the related articles (and any other references)
 * @param slug
 * @returns {Promise<*|null>}
 */
export const getBlogpostByForceV2ByOptions = async options => {
  const blogs = await forceGetContentV2('blog-post', options)

  if (blogs?.length === 1) {
    // TODO this can be improved caching the categories only one time
    const categories = await getAllCategoriesByForceV2()
    const blogpost = blogs[0]
    const blogpostWithCategoriesInRelatedArticles = populateRelatedArticles(blogpost, categories)
    return blogpostWithCategoriesInRelatedArticles
  } else {
    return null
  }
}

/**
 * get all blog posts
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllBlogpostByForceV2 = async (limit = 50, offset = 0) => {
  const { data } = await provenApi.get(`/${cmsUrl}/blog-posts?limit=${limit}&offset=${offset}`)

  return data
}

/**
 * get all blogpost
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllBlogpostByForceV2ForCache = async () => {
  const options = {
    limitPerRequest: 50,
    fields: 'fields=id,name,query,data'
  }
  const blogs = await forceGetContentV2('blog-post', options)

  return blogs?.length ? blogs : []
}

/**
 * get all ingredient (only metadata)
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllIngredientPageByForceV2OnlyMetadata = async () => {
  try {
    const options = {
      noTargeting: true,
      limitPerRequest: 20,
      fields: `${SEO_FIELDS_FROM_BUILDERIO}`
    }
    const ingredients = await forceGetContentV2('ingredient-page', options)

    return ingredients?.length ? ingredients : []
  } catch (e) {
    return []
  }
}

/**
 * get all blogpost
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllForceStrapiPage = async () => {
  const options = {
    limitPerRequest: 50
  }

  try {
    const forceStrapiPage = await forceGetContentV2('force-strapi-page', options)

    return forceStrapiPage?.length ? forceStrapiPage : []
  } catch (e) {
    return []
  }
}

export const forceGetAllContentWithAllReferences = async (modelName, options, maxLevels) => {
  const finalOptions = {
    noTraverse: false,
    ...options,
    userAttributes: {
      appEdition: APP_EDITION,
      ...(options.userAttributes || {})
    },
    data: {
      appEdition: APP_EDITION,
      ...(options.data || {})
    }
  }

  // Max limit supported by Builder content API is 100.
  let limitPerRequest = options.limit || 20
  let total = options.total
  let offset = 0
  let nextItems
  let allItems = []
  // let limitPerRequest = 20
  let amountDataQty = 0
  let itemsQty = 0

  do {
    const results = await forceGetContentV2(modelName, {
      ...finalOptions,
      limit: limitPerRequest,
      offset
    })

    nextItems = await Promise.all(results.map(content => forceResolveRefs(content, maxLevels)))

    if (!nextItems) {
      throw Error('forceGetAllContentWithAllReferences have NO DATA')
    }

    amountDataQty = nextItems.length
    allItems = allItems.concat(nextItems)
    offset += limitPerRequest
    itemsQty += amountDataQty
  } while (getConditionToKeepFetchData(total, itemsQty, amountDataQty, limitPerRequest, 1))

  return await Promise.all(allItems)
}

export const getAllFaqsByForce = async isSephora => {
  const { data } = await provenApi.get(`/${cmsUrl}/faqs?sephora=${isSephora}`)
  if (!data?.results) return []

  const allItems = await Promise.all(data.results.map(content => forceResolveRefs(content)))
  return await Promise.all(allItems)
}

/**
 * as there may be cyclical references (e.g. related articles) the number of references are limited with the param maxLevels
 * @param content
 * @param maxLevels
 * @returns {Promise<*>}
 */
export async function forceResolveRefs(content, maxLevels = MAX_LEVEL_REFERENCES) {
  const promises = []
  const result = traverse(content).map(function (child) {
    // Resolve references.
    if (child && child['@type'] === '@builder.io/core:Reference') {
      if (child.model) {
        if (maxLevels > 0) {
          promises.push(
            forceGetContentWithAllReferences(
              child.model,
              // NOTE: we don't want to enable targeting on references because we're fetching by ID.
              // Explicitly setting "noTargeting: true" overrides any targeting params that might find
              // their way into the request, for instance appEdition. This is a good thing,
              // because specifically for page models if you make a request with any one targeting param,
              // it will expect a URL, and if you don't provide a URL, then the request will fail even
              // if you provide an ID.
              {
                query: { id: child.id },
                noTargeting: true,
                noTraverse: false,
                data: { shared: { meta: { appEdition: APP_EDITION } } }
              },
              maxLevels - 1
            ).then(value => {
              const blocks = value?.data?.blocks

              // Strip out tracking pixel. We only need it once per page,
              // leaving it generates unnecessary network requests.
              if (blocks) {
                value = {
                  ...value,
                  data: {
                    ...value.data,
                    blocks: blocks?.filter(block => !block.id.startsWith('builder-pixel-'))
                  }
                }
              }

              this.update({ ...child, value })
            })
          )
        }
      }
    }

    // Resolve blocks.
    if (child?.key === 'blocks' || (child?.key === 'children' && Array.isArray(child.node))) {
      promises.push(resolveBlocks(child.node, this.update, content, maxLevels))
    }
  })

  await Promise.all(promises)
  return result
}

const resolveBlocks = async (blocks, update, content, maxLevels) => {
  let updateBlocks = [...blocks]
  const symbolBlocks = updateBlocks.filter(block => block['@type'] === '@builder.io/sdk:Element')

  await Promise.all([
    // Resolve symbol blocks.
    ...symbolBlocks.map(
      async symbolBlock => await resolveSymbolBlock(symbolBlock, updateBlocks, content, maxLevels)
    )
  ])

  // Commit the updated blocks.
  update(updateBlocks)
}

// Resolve symbol blocks.
//
// Symbol blocks contain, among other things:
//
//   - Bindings that feed data from the current page/section into the state's symbol.
//   - A pointer to the section content represented by the symbol.
//   - (Optional) A repeat property, which is a binding that resolves to an array. The
//     symbol block needs to be repeated for each item in the array, and each item
//     needs to be made available to the symbol's state.
//
// None of these things are resolved by the content API, except for the pointer to the
// section content, and even that is only resolved for the current content item's top
// level symbol blocks (recursive symbols aren't resolved).
//
// The code below resolves all of the above recursively and updates the content JSON
// with the resolved symbol blocks.
const resolveSymbolBlock = async (symbolBlock, updateBlocks, content, maxLevels) => {
  // Symbol content item's model name
  const model = symbolBlock?.component?.options?.symbol?.model
  // Symbol content item's ID
  const entry = symbolBlock?.component?.options?.symbol?.entry

  // Sanity check: only resolve if we have a non-blank model ID and we're still
  // traversing.
  if (model?.length > 0 && entry?.length > 0 && maxLevels > 0) {
    // We'll use this index to replace the old symbol block with the new resolved one.
    const updateIndex = updateBlocks.indexOf(symbolBlock)

    try {
      // This state is the parent content item's state (e.g., state used when
      // resolving a symbol's content input bindings).
      //
      // It's NOT the symbol's own internal state (e.g., state used when resolving
      // bindings within a symbol's content item), which is defined later.
      let symbolBlockState = {
        ...(content?.data?.state || {}),
        shared: { meta: { appEdition: APP_EDITION } }
      }

      // Resolve repeat.
      if (symbolBlock?.repeat?.collection) {
        const resolvedRepeatedBlock = await resolveRepeat(symbolBlock, symbolBlockState, maxLevels)

        // Replace the initial symbol block with copies of itself, each with its own
        // state and recursively resolved bindings, references, etc.
        updateBlocks.splice(updateIndex, 1, ...resolvedRepeatedBlock)
      } else {
        // If no repeat, directly resolve the symbol with its own state and recursively
        // resolved bindings, references, etc.
        const resolvedSymbolBlock = resolveSymbol(symbolBlock, symbolBlockState, maxLevels)

        updateBlocks.splice(updateIndex, 1, resolvedSymbolBlock)
      }
    } catch (err) {
      throw new Error(`Couldn't resolve symbol block: ${err}`)
    }
  }
}

const resolveSymbol = async (block, blockState, maxLevels) => {
  let symbolState = {
    shared: { meta: { appEdition: APP_EDITION } }
  }

  // Resolve content inputs and make them available to Builder's server-side state.
  if (block.bindings) {
    const blockContext = {}
    const options = {}

    // Resolve bindings.
    resolveBindings(block, options, blockState, blockContext)

    // Update state passed to Builder based on resolved bindings.
    symbolState = {
      ...symbolState,
      // Make any content inputs available to Builder's server state.
      ...(options?.component?.options?.symbol?.data || {})
    }
  }

  let content = await forceGetContentWithAllReferences(
    block.component.options.symbol.model,
    {
      query: { id: block.component.options.symbol.entry },
      noTargeting: true,
      noTraverse: false,
      data: symbolState
    },
    maxLevels - 1
  )
  const blocks = content?.data?.blocks

  // Strip out tracking pixel. We only need it once per page,
  // leaving it generates unnecessary network requests.
  if (blocks) {
    content = {
      ...content,
      data: {
        ...content.data,
        blocks: blocks?.filter(block => !block.id.startsWith('builder-pixel-'))
      }
    }
  }

  return content
}

const resolveBindings = (block, options, state, context) => {
  if (block.bindings) {
    // eslint-disable-next-line no-unused-vars
    for (const key in block.bindings) {
      if (!key.trim?.()) {
        continue
      }

      const value = stringToFunction(block.bindings[key])

      set(options, key, value(state, null, block, api(state), null, null, Builder, context))
    }
  }
}

// From a block that has a repeat, return an array of blocks for each item of the repeat value.
const resolveRepeat = async (repeatableBlock, blockState, maxLevels) => {
  // TODO: Pass in context shared by the root content item and its recursive children.
  // That's how context actually works in Builder.
  const context = {}
  // We're manually resolving the repeat and injecting blocks,
  // so we can remove the repeat from the JSON so that the SDK
  // doesn't duplicate blocks client-side.
  delete repeatableBlock.repeat

  const last = arr => arr[arr.length - 1]
  const collectionPath = repeatableBlock.repeat.collection
  const collectionName = last((collectionPath || '').trim().split('(')[0].trim().split('.'))
  const itemName =
    repeatableBlock.repeat.itemName || (collectionName ? collectionName + 'Item' : 'item')
  const array = this.stringToFunction(collectionPath)(
    blockState,
    null,
    repeatableBlock,
    api(blockState),
    Device,
    null,
    Builder,
    context
  )

  if (Array.isArray(array)) {
    const resolvedBlocks = await Promise.all(
      array.map(async (data, index) => {
        // TODO: Builder state produce the data
        const resolvedBlockState = {
          ...blockState,
          $index: index,
          $item: data,
          [itemName]: data,
          [`$${itemName}Index`]: index
        }
        const content = await resolveSymbol(repeatableBlock, resolvedBlockState, maxLevels)

        return getUpdatedSymbolBlock(repeatableBlock, content)
      })
    )

    return resolvedBlocks
  }

  return null
}

const getUpdatedSymbolBlock = (block, content) => ({
  ...block,
  component: {
    ...block.component,
    options: {
      ...block.component.options,
      symbol: {
        ...block.component.options.symbol,
        content
      }
    }
  }
})

export async function forceGetContentWithAllReferences(modelName, options, maxLevels) {
  /**
   * this exception is to handle the blog post to not enter in an infinite loop
   */
  if (modelName === 'blog-post' && options?.query?.id) {
    const content = await getBlogpostByForceV2ById(options?.query?.id)
    return content
  }

  const finalOptions = {
    noTraverse: false,
    ...options,
    userAttributes: {
      appEdition: APP_EDITION,
      ...(options.userAttributes || {})
    },
    data: {
      shared: { meta: { appEdition: APP_EDITION } },
      ...(options.data || {})
    }
  }

  // using custom function
  const results = await forceGetContentV2(modelName, finalOptions)

  const content =
    results.find(({ query }) =>
      query.find(
        q => q.property === 'appEdition' && isEqual([q.value].flat(), [APP_EDITION].flat())
      )
    ) ?? results.find(({ query }) => !query.find(q => q.property === 'appEdition'))

  const resolved = await forceResolveRefs(content, maxLevels)

  return resolved
}

export async function resolveRefs(content, maxLevels = MAX_LEVEL_REFERENCES) {
  const promises = []
  const result = traverse(content).map(function (child) {
    // Resolve references.
    if (child && child['@type'] === '@builder.io/core:Reference') {
      if (child.model) {
        if (maxLevels > 0) {
          promises.push(
            getContentWithAllReferences(
              child.model,
              // NOTE: we don't want to enable targeting on references because we're fetching by ID.
              // Explicitly setting "noTargeting: true" overrides any targeting params that might find
              // their way into the request, for instance appEdition. This is a good thing,
              // because specifically for page models if you make a request with any one targeting param,
              // it will expect a URL, and if you don't provide a URL, then the request will fail even
              // if you provide an ID.
              { query: { id: child.id }, noTargeting: true },
              maxLevels - 1
            ).then(value => {
              this.update({ ...child, value })
            })
          )
        }
      }
    }

    // Resolve symbols.
    if (child && child['@type'] === '@builder.io/sdk:Element') {
      const model = child?.component?.options?.symbol?.model
      const entry = child?.component?.options?.symbol?.entry

      if (model && model.length > 0 && entry && entry.length > 0) {
        if (maxLevels > 0) {
          promises.push(
            getContentWithAllReferences(
              child.component.options.symbol.model,
              { query: { id: child.component.options.symbol.entry }, noTargeting: true },
              maxLevels - 1
            ).then(content => {
              this.update({
                ...child,
                component: {
                  ...child.component,
                  options: {
                    ...child.component.options,
                    symbol: { ...child.component.options.symbol, content }
                  }
                }
              })
            })
          )
        }
      }
    }
  })

  await Promise.all(promises)
  return result
}

export async function getContentWithAllReferences(modelName, options, maxLevels) {
  const content = await builder.get(modelName, options).toPromise()

  return await resolveRefs(content, maxLevels)
}

/**
 * This is the last 8 chars of the main space in builder
 * we are going to use it to check for the production main
 * @type {string}
 */
const BUILDER_MAIN_SPACE_LAST_HASH = '68ae6f69'

/**
 * this function check the builder space (api key) and add a prefix in the entityId if it is need
 * if the space is main, then no prefix is added
 * if the prefix is NOT main then the prefix is added
 * @param entityId
 * qparam apiKey: builder api key
 */
export function getBuilderEntityIdWithPrefixIfNeed(entityId, apiKey = '') {
  if (!entityId || typeof apiKey !== 'string') {
    return ''
  }

  if (!apiKey || apiKey.endsWith(BUILDER_MAIN_SPACE_LAST_HASH)) {
    return `${entityId}`
  }

  return `${apiKey}_${entityId}`
}

const convertHtmlToText = htmlText => {
  return htmlText.replace(/<[^>]+>/g, '')
}
const transformFaq = questions => {
  if (!questions?.length) {
    return []
  }

  return questions.map((data, index) => {
    return {
      _id: `question-${index}`,
      question: data?.question,
      answer: data?.answer
    }
  })
}

const transformFaqCollectionSecondLevel = subcategories => {
  if (!subcategories?.length) {
    return []
  }
  // the response is only one
  const faq_groups = subcategories.map((data, index) => {
    return {
      faqs: transformFaq(data?.questions),
      faq_groups: [],
      _id: `faq-groups-subcategories-${index}`,
      title: data.subcategoryName
    }
  })

  return faq_groups
}

const transformFaqCollectionFirstLevel = faqsFromBuilder => {
  if (!faqsFromBuilder?.length || !faqsFromBuilder[0].data?.categories) {
    return []
  }
  // the response is only one
  const faqFromBuilderFirstElement = faqsFromBuilder[0]
  const faq_groups = faqFromBuilderFirstElement?.data?.categories.map((data, index) => {
    return {
      faqs: transformFaq(data?.questions),
      faq_groups: transformFaqCollectionSecondLevel(data?.subcategories),
      _id: `faq-groups-${index}`,
      title: convertHtmlToText(data.categoryName)
    }
  })

  return {
    faq_groups: faq_groups
  }
}

/**
 * get a faqs entity by slug
 * @param slug
 * @returns {Promise<*|null>}
 */
export const getFAQsByForceV2BySlug = async slug => {
  const options = {
    userAttributes: {},
    query: {
      data: {
        slug: slug
      }
    },
    maxTries: 1,
    limitPerRequest: 10,
    total: 10
  }
  const faqsFromBuilder = await forceGetAllContentWithAllReferences('faqs', options)

  if (faqsFromBuilder?.length === 1) {
    const faqReformat = transformFaqCollectionFirstLevel(faqsFromBuilder)

    return faqReformat
  } else {
    return null
  }
}

/**
 * get all page (only metadata)
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllPageByForceV2OnlyMetadata = async () => {
  try {
    const options = {
      //      noTargeting: true,
      limitPerRequest: 20,
      ignoreAppEdition: true, // with this flag in true we are not adding appEdition userAttribute. If we need LP exclusive for an app edition we'd have to remove this option, and make sure that LPs have right targeting
      fields: `${SEO_FIELDS_FROM_BUILDERIO}`
    }
    //TODO remove no longer used options fields in the rest of getAll**** functions

    const page = await forceGetContentV2('page', options)
    return page?.length ? page : []
  } catch (e) {
    return []
  }
}

/**
 * get all pdp (only metadata)
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllPdpByForceV2OnlyMetadata = async () => {
  try {
    const options = {
      noTargeting: true,
      limitPerRequest: 20,
      fields: `${SEO_FIELDS_FROM_BUILDERIO}`
    }

    const page = await forceGetContentV2('pdp', options)

    return page?.length ? page : []
  } catch (e) {
    return []
  }
}

/**
 * get all pdp (only metadata)
 * @returns {Promise<{length}|*|*[]>}
 */
export const getAllSkinTypePageByForceV2OnlyMetadata = async () => {
  try {
    const options = {
      noTargeting: true,
      limitPerRequest: 20,
      fields: `${SEO_FIELDS_FROM_BUILDERIO}`
    }

    const page = await forceGetContentV2('skin-type-page', options)

    return page?.length ? page : []
  } catch (e) {
    return []
  }
}

export const getAllSkinConcernPageByForceV2OnlyMetadata = async () => {
  try {
    const options = {
      noTargeting: true,
      limitPerRequest: 20,
      fields: `${SEO_FIELDS_FROM_BUILDERIO}`
    }

    const page = await forceGetContentV2('skin-concern-page', options)

    return page?.length ? page : []
  } catch (e) {
    return []
  }
}
