import initialState from './initialState'
import {
  GET_QUESTIONS,
  SET_ANSWER,
  SET_ANSWERS,
  SET_TRANSITION,
  POST_QUIZ_DATA_ERROR,
  POST_QUIZ_DATA_SUCCESS,
  SET_VALIDATION_ERROR,
  SET_REFERRER,
  SUCCESS_USER_QUIZ_DATA,
  SET_QUIZ_PENDING_STATUS,
  CLEAR_QUIZ_ANSWERS,
  COMPLETED_QUIZ,
  CLEAR_APP_STATE,
  SET_VERSION,
  GET_INGREDIENTS,
  GET_CLINICAL_RESULTS,
  SET_AUTOCOMPLETE_INPUT_VALUE,
  SET_FOLLOWUP_QUESTIONS,
  SET_FOLLOWUP_ANSWERS,
  CLEAR_ACCOUNT_DATA,
  SET_TRANSITION_QUESTION,
  SET_QUIZ_SUMMARY_REDIRECT_URL
} from '../constants/actionTypes'

const reducer = (state = initialState.quiz, action) => {
  switch (action.type) {
    case GET_QUESTIONS: {
      const { questions, variant, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      if (variant === 'B') {
        return {
          ...state,
          questionnaires: {
            ...questionnairesState,
            [questionnaireId]: {
              ...questionnaireState,
              questions_b: questions
            }
          }
        }
      } else {
        return {
          ...state,
          questionnaires: {
            ...questionnairesState,
            [questionnaireId]: {
              ...questionnaireState,
              questions: questions
            }
          }
        }
      }
    }
    case SET_ANSWER: {
      const { answer, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || { answers: {} }
      const answersState = questionnaireState['answers'] || {}
      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            answers: {
              ...answersState,
              ...answer
            }
          }
        }
      }
    }
    case SET_REFERRER: {
      const { referrer } = action
      return { ...state, referrer }
    }
    case SET_ANSWERS: {
      const { answers, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            answers
          }
        }
      }
    }
    case SET_TRANSITION: {
      const { transition } = action
      return { ...state, transition }
    }
    case POST_QUIZ_DATA_SUCCESS: {
      const { result } = action
      return { ...state, result }
    }
    case POST_QUIZ_DATA_ERROR: {
      const { error } = action
      return { ...state, error }
    }
    case SET_VALIDATION_ERROR: {
      const { error, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            invalid: error
          }
        }
      }
    }
    case CLEAR_QUIZ_ANSWERS: {
      const { variant, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      return variant === 'B'
        ? {
            ...state,
            questionnaires: {
              ...questionnairesState,
              [questionnaireId]: {
                ...questionnaireState,
                answers: questionnaireState.version_b || {},
                ingredients: []
              }
            }
          }
        : variant === 'A'
        ? {
            ...state,
            questionnaires: {
              ...questionnairesState,
              [questionnaireId]: {
                ...questionnaireState,
                answers: questionnaireState.version_a || {},
                ingredients: []
              }
            }
          }
        : {
            ...state,
            questionnaires: {
              ...questionnairesState,
              [questionnaireId]: {
                ...questionnaireState,
                answers: {}
              }
            }
          }
    }
    case SUCCESS_USER_QUIZ_DATA: {
      const { questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      const answersState = questionnairesState[questionnaireId]?.answers || {}
      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            answers: { ...answersState },
            completed: false
          }
        }
      }
    }
    case COMPLETED_QUIZ: {
      const { questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            completed: true
          }
        }
      }
    }
    case SET_QUIZ_PENDING_STATUS: {
      // TODO check this status (if need to add into questionnaires)
      return {
        ...state,
        pending: action.data
      }
    }
    case CLEAR_APP_STATE: {
      // Do not clear the questions or versions
      const initialQuestionnaires = initialState.quiz.questionnaires
      const initialQuestionnaireKeys = Object.keys(initialQuestionnaires)
      const stateQuestionnaires = state.questionnaires
      const updatedQuestionnaires = initialQuestionnaireKeys.reduce((acc, cur) => {
        const initialQuestionnaire = initialQuestionnaires[cur]
        const stateQuestionnaire = stateQuestionnaires[cur]
        acc[cur] = {
          ...initialQuestionnaire,
          questions: stateQuestionnaire.questions,
          questions_b: stateQuestionnaire.questions_b,
          version_a: stateQuestionnaire.version_a,
          version_b: stateQuestionnaire.version_b
        }
        return acc
      }, {})
      return {
        ...initialState.quiz,
        questionnaires: updatedQuestionnaires
      }
    }
    case CLEAR_ACCOUNT_DATA: {
      const { followUpAnswers, followUpQuestions } = initialState.quiz
      return {
        ...state,
        followUpQuestions,
        followUpAnswers
      }
    }
    case SET_VERSION: {
      const { version, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      if (version && version.variant === 'B') {
        return {
          ...state,
          questionnaires: {
            ...questionnairesState,
            [questionnaireId]: {
              ...questionnaireState,
              version_b: version
            }
          }
        }
      } else {
        return {
          ...state,
          questionnaires: {
            ...questionnairesState,
            [questionnaireId]: {
              ...questionnaireState,
              version_a: version
            }
          }
        }
      }
    }
    case GET_INGREDIENTS: {
      const { data, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || { ingredients: [] }
      const ingredientsState = questionnaireState['ingredients']
        ? [...questionnaireState['ingredients']]
        : []

      const dataNames = data
        ? data.map(i => {
            return i.name
          })
        : []
      const statePreviousNamesInQuestionnaires =
        ingredientsState && ingredientsState.length && ingredientsState.length > 1
          ? ingredientsState.map(i => {
              return i.name
            })
          : []
      const isDupInQuestionnaires =
        statePreviousNamesInQuestionnaires &&
        statePreviousNamesInQuestionnaires.length &&
        statePreviousNamesInQuestionnaires.length > 0
          ? statePreviousNamesInQuestionnaires.every(i => dataNames.includes(i))
          : false
      //Add ingredient(s) only if different that the previous
      if (data && data.length && data.length > 0 && !isDupInQuestionnaires) {
        ingredientsState.push(...data)
      }

      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            ingredients: ingredientsState
          }
        }
      }
    }
    // case DECREMENT_INGREDIENTS: {
    //   const { num } = action
    //   const ingredientsCopy = state.ingredients
    //   const updatedIngredients =
    //     ingredientsCopy.length > num ? ingredientsCopy.splice(NaN, num) : []
    //   return {
    //     ...state,
    //     ingredients: updatedIngredients
    //   }
    // }
    case GET_CLINICAL_RESULTS: {
      const { data } = action
      return { ...state, clinicalResults: data }
    }
    case SET_AUTOCOMPLETE_INPUT_VALUE: {
      const { data, questionnaireId } = action
      const questionnairesState = state['questionnaires'] || {}
      const questionnaireState = questionnairesState[questionnaireId] || {}
      return {
        ...state,
        questionnaires: {
          ...questionnairesState,
          [questionnaireId]: {
            ...questionnaireState,
            autocompleteInputValue: data
          }
        }
      }
    }
    case SET_FOLLOWUP_QUESTIONS:
      return {
        ...state,
        followUpQuestions: action.questionnaire
      }
    case SET_FOLLOWUP_ANSWERS:
      return {
        ...state,
        followUpAnswers: action.answers
      }
    case SET_TRANSITION_QUESTION:
      return {
        ...state,
        transitionQuestion: action.payload
      }
    case SET_QUIZ_SUMMARY_REDIRECT_URL:
      return {
        ...state,
        redirectInfoUrl: action.redirectInfoUrl
      }

    default:
      return state
  }
}

export default reducer
